import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './API';

export const presalesApi = createApi({
  reducerPath: 'presalesApi',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    getPresales: builder.query({
      query: () => ({
        url: `/presales`,
        method: 'GET',
      }),
    }),
    getTopNewCoins: builder.query({
      query: () => ({
        url: `/top-new-coins`,
        method: 'GET',
      }),
    }),
    getNewListedCoins: builder.query({
      query: () => ({
        url: `/newly-listed-on-cmc`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetPresalesQuery,
  useGetTopNewCoinsQuery,
  useGetNewListedCoinsQuery,
} = presalesApi;
