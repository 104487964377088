import { Controller } from 'react-hook-form';
import Input from '../../../../components/Input/Input';
import Text from '../../../../components/Text/Text';
import Textarea from '../../../../components/Textarea/Textarea';
import SubmitCoinCard from '../../../SubmitCoin/modules/Card/Card';
import style from './Details.module.scss';

const DetailsSection = ({ setImages, images, control }) => {
  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const newImages = selectedFiles.map((file) => ({
      file,
      previewUrl: URL.createObjectURL(file),
    }));
    setImages((prevImages) => [...prevImages, ...newImages]);
  };
  const removeImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };
  return (
    <SubmitCoinCard className={style.details} title={'Provide details'}>
      <Text grey center className={style.details__text}>
        Provide supporting evidence and a thorough <br /> explanation for the
        reason you are reporting this project
      </Text>
      <div className={style.detailsBody}>
        <Controller
          control={control}
          name='comment'
          rules={{
            required: 'Required',
          }}
          render={({ field: { onChange, name, value } }) => {
            return (
              <Textarea
                placeholder={'Add comment'}
                border
                onChange={onChange}
                value={value}
              />
            );
          }}
        />
        <Input
          big
          border
          icon={
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M9.33332 1.51301V4.26671C9.33332 4.64008 9.33332 4.82676 9.40599 4.96937C9.4699 5.09481 9.57189 5.1968 9.69733 5.26071C9.83994 5.33337 10.0266 5.33337 10.4 5.33337H13.1537M13.3333 6.65886V11.4667C13.3333 12.5868 13.3333 13.1469 13.1153 13.5747C12.9236 13.951 12.6176 14.257 12.2413 14.4487C11.8135 14.6667 11.2534 14.6667 10.1333 14.6667H5.86666C4.74655 14.6667 4.1865 14.6667 3.75868 14.4487C3.38235 14.257 3.07639 13.951 2.88464 13.5747C2.66666 13.1469 2.66666 12.5868 2.66666 11.4667V4.53337C2.66666 3.41327 2.66666 2.85322 2.88464 2.42539C3.07639 2.04907 3.38235 1.74311 3.75868 1.55136C4.1865 1.33337 4.74655 1.33337 5.86666 1.33337H8.00784C8.49702 1.33337 8.74161 1.33337 8.97179 1.38863C9.17586 1.43763 9.37095 1.51844 9.54989 1.62809C9.75173 1.75178 9.92468 1.92473 10.2706 2.27063L12.3961 4.39612C12.742 4.74202 12.9149 4.91497 13.0386 5.1168C13.1483 5.29575 13.2291 5.49084 13.2781 5.69491C13.3333 5.92509 13.3333 6.16968 13.3333 6.65886Z'
                stroke='white'
                stroke-opacity='0.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          }
          onChange={handleImageChange}
          placeholder={'Add an image'}
          imageUpload
        />
        {images?.map((item, index) => {
          return (
            <div>
              <div style={{ color: 'white' }}>{item?.file?.name}</div>
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                onClick={() => {
                  removeImage(index);
                }}
              >
                <path
                  d='M12 4L4 12M4 4L12 12'
                  stroke='white'
                  stroke-opacity='0.5'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </div>
          );
        })}
      </div>
    </SubmitCoinCard>
  );
};

export default DetailsSection;
