import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './API';

export const boosterApi = createApi({
  reducerPath: 'boosterApi',
  tagTypes: ['Boosters'],
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    getBoosters: builder.query({
      query: () => ({
        url: `/boosters`,
        method: 'GET',
      }),
      invalidatesTags: ['Boosters'],
    }),
    purchaseBooster: builder.mutation({
      query: (data) => ({
        url: `/boosters/purchase`,
        method: 'POST',
        data: data,
      }),
      invalidatesTags: ['Boosters'],
    }),
    getBoostersHistory: builder.query({
      query: () => ({
        url: `/boosters/history`,
        method: 'GET',
      }),
      invalidatesTags: ['Boosters'],
    }),
    getBoostersProjects: builder.query({
      query: () => ({
        url: `/boosters/projects`,
        method: 'GET',
      }),
      invalidatesTags: ['Boosters'],
    }),
  }),
});

export const {
  useGetBoostersQuery,
  usePurchaseBoosterMutation,
  useGetBoostersHistoryQuery,
  useGetBoostersProjectsQuery,
} = boosterApi;
