import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";
import { Swiper, SwiperSlide } from "swiper/react";
import BlogCard from "../../../../components/BlogCard/BlogCard";
import Title from "../../../../components/Title/Title";
import img from "../Content/assets/img.jpg";
import Button from "../../../../components/Button/Button";
import style from "./Popular.module.scss";
import "swiper/css";
import UseGeneralHooks from "../../../../hooks/useGeneralHooks";

const PopularSection = ({activeCategoryList, popular}) => {
  const sliderRef = useRef(null);
  const [disablePrevSliderBtn, setDisablePrevSliderBtn] = useState(true);
  const [disableNextSliderBtn, setDisableNextSliderBtn] = useState(false);
  const isTablet = useMediaQuery("(max-width:991px)");
  const isMobile = useMediaQuery("(max-width:767px)");
  const [visibleSlides, setVisibleSlides] = useState(4);
  const {navigate} = UseGeneralHooks();

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  useEffect(() => {
    isTablet && setVisibleSlides(3);
    isMobile && setVisibleSlides(1);
  }, [isTablet, isMobile]);

  return (
    <div className={style.popular}>
      <div className={style.popularTop}>
        <Title.H1 className={style.popular__title}>Popular</Title.H1>
        <div className={style.popularTop__nav}>
          <Button disabled={disablePrevSliderBtn} onClick={handlePrev} onlyIcon>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 12L6 8L10 4"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Button>
          <Button disabled={disableNextSliderBtn} onClick={handleNext} onlyIcon>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 12L10 8L6 4"
                stroke="white"
                stroke-opacity="0.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Button>
        </div>
      </div>
      <Swiper
        ref={sliderRef}
        className={style.popularCards}
        slidesPerView={visibleSlides}
        spaceBetween={16}
        onSlideChange={(swiper) => {
          if (swiper.activeIndex === 0) {
            setDisablePrevSliderBtn(true);
          } else {
            setDisablePrevSliderBtn(false);
          }
          if (swiper.activeIndex === swiper.slides.length - visibleSlides) {
            setDisableNextSliderBtn(true);
          } else {
            setDisableNextSliderBtn(false);
          }
        }}
      >
        {popular?.map((post, index) => {          
          return (
            <SwiperSlide>
              <BlogCard
                key={index}
                className={style.popularCards__item}
                img={post?.image ?? img}
                tag={post?.categories[0]?.title}
                title={post?.title}
                text={post?.announce}
                info={{
                  date: post?.published_at,
                  time: post.time_to_read ,
                }}
                onClick={() => navigate(post?.url)}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default PopularSection;
