import { useState } from 'react';
import { useGetAirdropsQuery } from '../../store/airdropsApi';
import AirdropsTable from './modules/Table/Table';
import AirdropsTop from './modules/Top/Top';
import style from './index.module.scss';

const AirdropsPage = () => {

  const [category, setCategory] = useState("live")
  const {data: airdrops} = useGetAirdropsQuery(category);
  
  return (
    <div className={style.airdrops}>
      <div className='__container'>
        <AirdropsTop setCategory={setCategory} categories={airdrops?.categories} category={category} />
        <AirdropsTable airdrops={airdrops?.coins} category={category} />
      </div>
    </div>
  );
};

export default AirdropsPage;
