import Banner from './components/Banner/Banner';
import style from './index.module.scss';
import ProjectInfo from './modules/Info/Info';
import ProjectTop from './modules/Top/Top';
import img from './assets/img.jpg';
import banner from '../Main/assets/img.jpg';
import Banners from '../Main/components/Banners/Banners';
import MarketDetails from './modules/MarketDetails/MarketDetails';
import CommunitySection from './modules/Community/Community';
import Transactions from './modules/Transactions/Transactions';
import PresaleDetails from './modules/PresaleDetails/PresaleDetails';
import { useGetCoinByIdQuery } from '../../store/coinsApi';
import UseGeneralHooks from '../../hooks/useGeneralHooks';

const ProjectPage = () => {
  const { params } = UseGeneralHooks();

  const { data: coinByIdInfo } = useGetCoinByIdQuery(params?.id);

  return (
    <div>
      {coinByIdInfo && (
        <>
          <ProjectTop
            coin={coinByIdInfo?.coin}
            can_be_updated={coinByIdInfo?.coin?.can_be_updated}
          />
          <Banner
            img={coinByIdInfo?.coin?.image ? coinByIdInfo?.coin?.image : img}
            coinId={coinByIdInfo?.coin?.id}
          />
          <ProjectInfo coinByIdInfo={coinByIdInfo?.coin} />
          <div className={`__container ${style.banner}`}>
            <Banners.Container withContacts>
              <Banners.Card img={banner} />
            </Banners.Container>
          </div>
          <PresaleDetails coinByIdInfo={coinByIdInfo} />
          <div className={`__container ${style.banner}`}>
            <Banners.Container withContacts>
              <Banners.Card img={banner} />
            </Banners.Container>
          </div>
          <MarketDetails coin={coinByIdInfo?.coin} />
          <Transactions />
          <CommunitySection />
        </>
      )}
    </div>
  );
};

export default ProjectPage;
