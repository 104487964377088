import { Outlet } from "react-router-dom";
import style from "./index.module.scss";
import AdvertiseTop from "./modules/Top/Top";
import { useState } from "react";
import { createContext } from "react";

export const MyDataContext = createContext();

const AdvertisePage = () => {
  const [activeCategory, setActiveCategory] = useState("new");

  return (
    <MyDataContext.Provider value={activeCategory}>
      <div className={style.advertise}>
        <div className="__container">
          <AdvertiseTop setActiveCategory={setActiveCategory} activeCategory={activeCategory} />
          <div className={style.advertiseContent}>
            <Outlet activeCategory={activeCategory} />
          </div>
        </div>
      </div>
    </MyDataContext.Provider>
  );
};

export default AdvertisePage;
