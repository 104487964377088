import Table from '../../../../components/Table/Table';
import TokenInfo from '../../../../components/TokenInfo/TokenInfo';
import style from './Table.module.scss';
import img from '../../../../components/Search/modules/Slider/assets/logo.jpg';
import { useEffect, useState } from 'react';
import Text from '../../../../components/Text/Text';
import Button from '../../../../components/Button/Button';
import { useMediaQuery } from 'usehooks-ts';
import {
  useAttachWatchlistMutation,
  useDeleteWatchlistMutation,
} from '../../../../store/watchlistApi';
import { useVoteCoinsMutation } from '../../../../store/coinsApi';
import ClipLoader from 'react-spinners/ClipLoader';
import UseGeneralHooks from '../../../../hooks/useGeneralHooks';

const AirdropsTable = ({ airdrops, category }) => {
  const isTablet = useMediaQuery('(max-width:991px)');
  const [attachToWatchlist] = useAttachWatchlistMutation();
  const [detachWatchlist] = useDeleteWatchlistMutation();
  const [voteCoinReq, { isLoading }] = useVoteCoinsMutation();
  const [activeItem, setActiveItem] = useState(null);
  const { navigate } = UseGeneralHooks();
  const { formatDate } = UseGeneralHooks();

  return (
    <div className={style.table}>
      <Table.Container
        side={
          isTablet && (
            <Table.Side className={style.table__itemSide}>
              <Table.Row>
                <Table.Col></Table.Col>
                <Table.Col>Name</Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col>
                  <button className={style.favorite}>
                    <svg
                      width='14'
                      height='13'
                      viewBox='0 0 14 13'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M6.26134 0.775945C6.53462 0.11891 7.46538 0.118909 7.73866 0.775943L8.92838 3.63637C9.04358 3.91336 9.30407 4.10261 9.6031 4.12659L12.6912 4.37416C13.4005 4.43102 13.6881 5.31623 13.1477 5.77916L10.7949 7.79457C10.5671 7.98974 10.4676 8.29596 10.5372 8.58776L11.256 11.6012C11.4211 12.2934 10.6681 12.8405 10.0608 12.4695L7.417 10.8547C7.16099 10.6983 6.83901 10.6983 6.583 10.8547L3.93918 12.4695C3.3319 12.8405 2.5789 12.2934 2.74401 11.6012L3.46282 8.58776C3.53243 8.29596 3.43293 7.98974 3.2051 7.79458L0.852314 5.77917C0.311884 5.31623 0.599504 4.43102 1.30883 4.37416L4.3969 4.12659C4.69593 4.10261 4.95642 3.91336 5.07162 3.63637L6.26134 0.775945Z'
                        fill='white'
                        fill-opacity='0.1'
                      />
                    </svg>
                  </button>
                </Table.Col>
                <Table.Col>
                  <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col>
                  <button className={style.favorite}>
                    <svg
                      width='14'
                      height='13'
                      viewBox='0 0 14 13'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M6.26134 0.775945C6.53462 0.11891 7.46538 0.118909 7.73866 0.775943L8.92838 3.63637C9.04358 3.91336 9.30407 4.10261 9.6031 4.12659L12.6912 4.37416C13.4005 4.43102 13.6881 5.31623 13.1477 5.77916L10.7949 7.79457C10.5671 7.98974 10.4676 8.29596 10.5372 8.58776L11.256 11.6012C11.4211 12.2934 10.6681 12.8405 10.0608 12.4695L7.417 10.8547C7.16099 10.6983 6.83901 10.6983 6.583 10.8547L3.93918 12.4695C3.3319 12.8405 2.5789 12.2934 2.74401 11.6012L3.46282 8.58776C3.53243 8.29596 3.43293 7.98974 3.2051 7.79458L0.852314 5.77917C0.311884 5.31623 0.599504 4.43102 1.30883 4.37416L4.3969 4.12659C4.69593 4.10261 4.95642 3.91336 5.07162 3.63637L6.26134 0.775945Z'
                        fill='white'
                        fill-opacity='0.1'
                      />
                    </svg>
                  </button>
                </Table.Col>
                <Table.Col>
                  <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col>
                  <button className={style.favorite}>
                    <svg
                      width='14'
                      height='13'
                      viewBox='0 0 14 13'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M6.26134 0.775945C6.53462 0.11891 7.46538 0.118909 7.73866 0.775943L8.92838 3.63637C9.04358 3.91336 9.30407 4.10261 9.6031 4.12659L12.6912 4.37416C13.4005 4.43102 13.6881 5.31623 13.1477 5.77916L10.7949 7.79457C10.5671 7.98974 10.4676 8.29596 10.5372 8.58776L11.256 11.6012C11.4211 12.2934 10.6681 12.8405 10.0608 12.4695L7.417 10.8547C7.16099 10.6983 6.83901 10.6983 6.583 10.8547L3.93918 12.4695C3.3319 12.8405 2.5789 12.2934 2.74401 11.6012L3.46282 8.58776C3.53243 8.29596 3.43293 7.98974 3.2051 7.79458L0.852314 5.77917C0.311884 5.31623 0.599504 4.43102 1.30883 4.37416L4.3969 4.12659C4.69593 4.10261 4.95642 3.91336 5.07162 3.63637L6.26134 0.775945Z'
                        fill='white'
                        fill-opacity='0.1'
                      />
                    </svg>
                  </button>
                </Table.Col>
                <Table.Col>
                  <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col>
                  <button className={style.favorite}>
                    <svg
                      width='14'
                      height='13'
                      viewBox='0 0 14 13'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M6.26134 0.775945C6.53462 0.11891 7.46538 0.118909 7.73866 0.775943L8.92838 3.63637C9.04358 3.91336 9.30407 4.10261 9.6031 4.12659L12.6912 4.37416C13.4005 4.43102 13.6881 5.31623 13.1477 5.77916L10.7949 7.79457C10.5671 7.98974 10.4676 8.29596 10.5372 8.58776L11.256 11.6012C11.4211 12.2934 10.6681 12.8405 10.0608 12.4695L7.417 10.8547C7.16099 10.6983 6.83901 10.6983 6.583 10.8547L3.93918 12.4695C3.3319 12.8405 2.5789 12.2934 2.74401 11.6012L3.46282 8.58776C3.53243 8.29596 3.43293 7.98974 3.2051 7.79458L0.852314 5.77917C0.311884 5.31623 0.599504 4.43102 1.30883 4.37416L4.3969 4.12659C4.69593 4.10261 4.95642 3.91336 5.07162 3.63637L6.26134 0.775945Z'
                        fill='white'
                        fill-opacity='0.1'
                      />
                    </svg>
                  </button>
                </Table.Col>
                <Table.Col>
                  <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
                </Table.Col>
              </Table.Row>
            </Table.Side>
          )
        }
        className={style.table__item}
      >
        <Table.Head>
          <Table.Row>
            {!isTablet && (
              <>
                <Table.Col center>#</Table.Col>
                <Table.Col></Table.Col>
                <Table.Col>Name</Table.Col>
              </>
            )}
            <Table.Col>Type</Table.Col>
            <Table.Col center>Chains</Table.Col>
            {/* <Table.Col center>Tokens Reward</Table.Col>
            <Table.Col center>Participants</Table.Col>
            <Table.Col right>Winners</Table.Col> */}
            <Table.Col>Starting Date</Table.Col>
            <Table.Col>Expiration Date</Table.Col>
            <Table.Col></Table.Col>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {airdrops?.map((item, index) => {            
            return (
              <Table.Row key={index} onClick={() => navigate(item?.coin?.url)}>
                {!isTablet && (
                  <>
                    <Table.Col center>{index + 1}</Table.Col>
                    <Table.Col>
                      <button
                        className={style.favorite}
                        onClick={(event) => {
                          event?.stopPropagation();
                          item?.is_watchlisted
                            ? detachWatchlist(item?.coin?.id)
                            : attachToWatchlist(item?.coin?.id);
                        }}
                      >
                        <svg
                          width='14'
                          height='13'
                          viewBox='0 0 14 13'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M6.26134 0.775945C6.53462 0.11891 7.46538 0.118909 7.73866 0.775943L8.92838 3.63637C9.04358 3.91336 9.30407 4.10261 9.6031 4.12659L12.6912 4.37416C13.4005 4.43102 13.6881 5.31623 13.1477 5.77916L10.7949 7.79457C10.5671 7.98974 10.4676 8.29596 10.5372 8.58776L11.256 11.6012C11.4211 12.2934 10.6681 12.8405 10.0608 12.4695L7.417 10.8547C7.16099 10.6983 6.83901 10.6983 6.583 10.8547L3.93918 12.4695C3.3319 12.8405 2.5789 12.2934 2.74401 11.6012L3.46282 8.58776C3.53243 8.29596 3.43293 7.98974 3.2051 7.79458L0.852314 5.77917C0.311884 5.31623 0.599504 4.43102 1.30883 4.37416L4.3969 4.12659C4.69593 4.10261 4.95642 3.91336 5.07162 3.63637L6.26134 0.775945Z'
                            fill='white'
                            fill-opacity='0.1'
                          />
                        </svg>
                      </button>
                    </Table.Col>
                    <Table.Col>
                      <TokenInfo
                        icon={item?.coin?.image ? item?.coin?.image : img}
                        name={item?.coin?.title}
                        label={item?.coin?.symbol}
                      />
                    </Table.Col>
                  </>
                )}
                <Table.Col>
                  <TypeDiv title={category} />
                </Table.Col>
                <Table.Col center>
                  <Chain
                    name={item?.network}
                    icon={
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M7.91154 3L7.8479 3.21617V9.4884L7.91154 9.55189L10.823 7.83091L7.91154 3Z'
                          fill='#F7F7F7'
                        />
                        <path
                          d='M7.91151 3L5 7.83091L7.91151 9.55189V6.50752V3Z'
                          fill='white'
                        />
                        <path
                          d='M7.91087 10.1029L7.875 10.1466V12.3809L7.91087 12.4856L10.8241 8.38281L7.91087 10.1029Z'
                          fill='#F8F8F8'
                        />
                        <path
                          d='M7.91151 12.4856V10.1029L5 8.38281L7.91151 12.4856Z'
                          fill='white'
                        />
                        <path
                          d='M7.91211 9.55218L10.8235 7.8312L7.91211 6.50781V9.55218Z'
                          fill='#ECECEC'
                        />
                        <path
                          d='M5 7.8312L7.91151 9.55218V6.50781L5 7.8312Z'
                          fill='#F1F1F1'
                        />
                      </svg>
                    }
                  />
                </Table.Col>
                {/* <Table.Col>500M</Table.Col>
                <Table.Col>120K</Table.Col>
                <Table.Col right>
                  <Text>1000 👑</Text>
                </Table.Col> */}
                <Table.Col>
                  <Text>{formatDate(item?.airdrops?.from)}</Text>
                </Table.Col>
                <Table.Col>
                  <Text>{formatDate(item?.airdrops?.to)}</Text>
                </Table.Col>
                <Table.Col>
                  <Button
                    sm
                    filledGrey
                    onClick={(event) => {
                      event?.stopPropagation();
                      voteCoinReq(item?.coin?.id);
                      setActiveItem(item?.coin?.id);
                    }}
                    disabled={item?.is_voted}
                  >
                    {item?.coin?.id === activeItem && isLoading ? (
                      <ClipLoader color={'black'} size={15} />
                    ) : (
                      'Join'
                    )}
                  </Button>
                </Table.Col>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table.Container>
    </div>
  );
};

const TypeDiv = ({ title }) => {
  const [tagColor, setTagColor] = useState('');

  useEffect(() => {
    switch (title) {
      case 'liveive':
        setTagColor('green');
        break;
      case 'upcoming':
        setTagColor('yellow');
        break;
      case 'ended':
        setTagColor('red');
        break;

      default:
        break;
    }
  }, []);

  return (
    <div
      className={`${style.type}
    ${tagColor === 'purple' && style.purple}
          ${tagColor === 'yellow' && style.yellow}
          ${tagColor === 'blue' && style.blue}
          ${tagColor === 'red' && style.red}
          ${tagColor === 'green' && style.green}
  `}
    >
      {title}
    </div>
  );
};

const Chain = ({ name, icon }) => {
  return (
    <div className={style.chain}>
      {name}
      <span className={style.chain__icon}>{icon}</span>
    </div>
  );
};

export default AirdropsTable;
