import { useMediaQuery } from 'usehooks-ts';
import Button from '../../../../../../components/Button/Button';
import Text from '../../../../../../components/Text/Text';
import Title from '../../../../../../components/Title/Title';
import style from './Tokenomics.module.scss';
import Chart from 'react-apexcharts';
import UseGeneralHooks from '../../../../../../hooks/useGeneralHooks';

const options = {
  chart: {
    type: 'radialBar',
  },
  plotOptions: {
    radialBar: {
      dataLabels: {
        name: {
          fontSize: '22px',
        },
        value: {
          fontSize: '16px',
        },
        total: {
          show: false,
          label: 'Total',
          formatter: function (w) {
            // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
            return 249;
          },
        },
      },
      hollow: {
        margin: 0,
        size: '30%',
        imageWidth: 0,
        imageHeight: 0,
        imageClipped: false,
      },
      track: {
        background: 'rgba(255, 255, 255, 0.05)',
        strokeWidth: '100%',
        margin: 4,
        dropShadow: {
          enabled: false,
        },
      },
    },
  },
  colors: ['#FFA200', '#006BB9', '#5FE7C7', '#FF007A', '#00FF94', '#AD00FF'],
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'dark',
      type: 'vertical',
      shadeIntensity: 0.5,
      gradientToColors: [
        '#FFD07E',
        '#6AC0FF',
        '#FFFFFF',
        '#FFFFFF',
        '#FFFFFF',
        '#FFFFFF',
      ],
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100],
    },
  },
  stroke: {
    lineCap: 'round',
  },
  labels: [
    'Presale',
    'Treasury',
    'Community',
    'Liquidity',
    'Marketing',
    'Development',
  ],
};

const series = [45, 35, 15, 30, 25, 10];

const Tokenomics = ({ presales }) => {
  const isMobile = useMediaQuery('(max-width:767px)');
  const { formatDate } = UseGeneralHooks();

  return (
    <div className={style.tokenomics}>
      <div className={style.tokenomicsTop}>
        <Title.H3 fz16 className={style.tokenomicsTop__title}>
          Tokenomics
        </Title.H3>
        <Button className={style.tokenomicsTop__btn}>
          Export
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M14 10V10.8C14 11.9201 14 12.4802 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.4802 14 11.9201 14 10.8 14H5.2C4.07989 14 3.51984 14 3.09202 13.782C2.71569 13.5903 2.40973 13.2843 2.21799 12.908C2 12.4802 2 11.9201 2 10.8V10M11.3333 6.66667L8 10M8 10L4.66667 6.66667M8 10V2'
              stroke='white'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </Button>
      </div>
      <div className={style.tokenomicsChart}>
        <Chart
          className={style.tokenomicsChart__item}
          options={options}
          series={series}
          // width='100%'
          // height={'100%'}
          width={isMobile ? '348px' : '400px'}
          height={isMobile ? '348px' : '400px'}
          type='radialBar'
        />
      </div>
      <div className={style.tokenomicsBody}>
        <div className={style.tokenomicsBody__legend}>
          <LegendItem color={style.orange} title={'Presale'} value={'45%'} />
          <LegendItem color={style.blue} title={'Treasury'} value={'35%'} />
          <LegendItem
            color={style.lightGreen}
            title={'Community'}
            value={'15%'}
          />
          <LegendItem color={style.pink} title={'Liquidity'} value={'30%'} />
          <LegendItem color={style.green} title={'Marketing'} value={'25%'} />
          <LegendItem
            color={style.purple}
            title={'Development'}
            value={'10%'}
          />
        </div>
        <div className={style.tokenomicsBody__dates}>
          <DateItem
            title={
              presales &&
              presales?.started_at &&
              formatDate(presales?.started_at)
            }
            label={'Start Date'}
          />
          <DateItem
            title={
              presales && presales?.ended_at && formatDate(presales?.ended_at)
            }
            label={'End Date'}
          />
          <DateItem
            title={presales?.softcap && presales?.softcap}
            label={'Soft / Hard Cap'}
          />
        </div>
      </div>
    </div>
  );
};

const LegendItem = ({ color, title, value }) => {
  return (
    <div className={style.tokenomicsBody__legendItem}>
      <span className={color}></span>
      <Text>{title}</Text>
      <Text className={style.value} grey>
        {value}
      </Text>
    </div>
  );
};

const DateItem = ({ title, label }) => {
  return (
    <div className={style.tokenomicsBody__datesItem}>
      <Text>{title}</Text>
      <Text grey>{label}</Text>
    </div>
  );
};

export default Tokenomics;
