import { useRef, useState } from 'react';
import style from './Textarea.module.scss';

const Textarea = ({
  className,
  placeholder,
  icon,
  type = 'text',
  withClear,
  onClick,
  big,
  border,
  value,
  onChange
}) => {
  const inputRef = useRef(null);
  const [isClearVisible, setIsClearVisible] = useState(false);

  const handleInputClear = () => {
    if (inputRef.current.value.length > 0) {
      setIsClearVisible(true);
    } else {
      setIsClearVisible(false);
    }
  };

  const clearInput = () => {
    inputRef.current.value = '';
    setIsClearVisible(false);
  };

  return (
    <label
      onClick={onClick}
      className={`${style.input} 
    ${big && style.big}
      ${border && style.border}
    ${className}`}
    >
      {icon && <div className={style.input__icon}>{icon}</div>}
      <textarea
        ref={inputRef}
        type={type}
        onInput={withClear && handleInputClear}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
      {withClear && (
        <button
          onClick={clearInput}
          className={`${style.input__clear} ${isClearVisible && style.visible}`}
        >
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M12 4L4 12M4 4L12 12'
              stroke='white'
              stroke-opacity='0.5'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </button>
      )}
    </label>
  );
};

export default Textarea;
