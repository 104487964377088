import SelectListing from './modules/SelectListing';
import SeasonSection from './modules/Season';
import ReportListingTop from './modules/Top';
import style from './ReportListing.module.scss';
import Text from '../../components/Text/Text';
import Button from '../../components/Button/Button';
import ClipLoader from 'react-spinners/ClipLoader';
import DetailsSection from './modules/Details';
import { useFieldArray, useForm } from 'react-hook-form';
import { useState } from 'react';

const ReportListingPage = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      listing: [],
      images: [],
    },
  });

  const {
    fields: listingFields,
    append: appendListing,
    remove: removeListing,
  } = useFieldArray({
    control,
    name: 'listing',
  });

  const [images, setImages] = useState([]);

  const onSubmit = (data) => {
    const payload = {
      listing: data?.listing?.map((item) => item?.id),
      reason: data?.reason,
      comment: data?.comment,
      images,
    };
  };

  return (
    <>
      <ReportListingTop />
      <div className='__container'>
        <div className={style.reportBody}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SelectListing
              listingFields={listingFields}
              appendListing={appendListing}
              removeListing={removeListing}
            />
            <SeasonSection control={control} />
            <DetailsSection
              setImages={setImages}
              images={images}
              control={control}
            />
            <div className={style.bottom}>
              <Text grey className={style.bottom__text}>
                By clicking “Submit” you agree <br /> with our{' '}
                <a href=''>Terms and Conditions</a>
              </Text>
              <Button filled className={style.bottom__btn} type={'submit'}>
                Submit Coin
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ReportListingPage;
