import { useMemo, useState } from 'react';
import Text from '../../../../components/Text/Text';
import Title from '../../../../components/Title/Title';
import BoostCard from '../../components/BoostCard/BoostCard';
import Purchase from '../../modules/Purchase/Purchase';
import style from './Buy.module.scss';
import { useGetBoostersQuery } from '../../../../store/boosterApi';

const cardList = [
  { id: 0, title: '1X', rate: 1, bonus: '1X', price: '$10', checked: false },
  { id: 1, title: '1X', rate: 1, bonus: '1X', price: '$10', checked: false },
  { id: 2, title: '1X', rate: 1, bonus: '1X', price: '$10', checked: false },
  { id: 3, title: '1X', rate: 1, bonus: '1X', price: '$10', checked: false },
  { id: 4, title: '1X', rate: 1, bonus: '1X', price: '$10', checked: false },
  { id: 5, title: '1X', rate: 1, bonus: '1X', price: '$10', checked: false },
  { id: 6, title: '1X', rate: 1, bonus: '1X', price: '$10', checked: false },
  {
    id: 7,
    title: '750X',
    rate: 8,
    bonus: '500X',
    price: '$5,000',
    checked: false,
  },
];

const BuySection = () => {
  const [selectedCards, setSelectedCards] = useState([]);

  const chooseCard = (item, type) => {
    if (type === 'add') {
      if (selectedCards.filter((card) => card.id === item.id).length < 1) {
        setSelectedCards([...selectedCards, item]);
      }
    } else {
      setSelectedCards([
        ...selectedCards.filter((card) => card.id !== item.id),
      ]);
    }
  };

  const selectedCardIds = useMemo(
    () => selectedCards.map((card) => card.id),
    [selectedCards]
  );

  const { data: boosters } = useGetBoostersQuery();

  return (
    <div className={style.buy}>
      <div className={style.buyCards}>
        {boosters?.boosters?.map((item, index) => {
          return (
            <BoostCard
              key={index}
              id={item.id}
              className={style.buyCards__item}
              title={'1X'}
              rate={1}
              bonus={item.bonus}
              price={item.price}
              choose={chooseCard}
              selectedCards={selectedCards}
            />
          );
        })}
        <div className={style.buyCards__text}>
          <Title.H3>Hey, buddy</Title.H3>
          <Text grey>
            You can choose <br /> several boosts <br /> at once
          </Text>
        </div>
      </div>
      <Purchase
        choose={chooseCard}
        className={style.buyPurchase}
        cardList={selectedCards}
        selectedCardIds={selectedCardIds}
        setSelectedCards={setSelectedCards}
      />
    </div>
  );
};

export default BuySection;
