import { useState } from "react";
import Input from "../../../../components/Input/Input";
import Select from "../../../../components/Select/Select";
import Text from "../../../../components/Text/Text";
import AddCard from "../../components/AddCard/AddCard";
import SubmitCoinCard from "../../modules/Card/Card";
import style from "./Airdrop.module.scss";
import { Controller } from "react-hook-form";

const AirdropSection = ({
  control, reset
}) => {
  const [isShown, setIsShown] = useState(false);

  function onRemove() {
    setIsShown(false);
    reset({})
  }

  return (
    <>
      {isShown && (
        <SubmitCoinCard
          onRemove={onRemove}
          title={"Airdrop"}
          className={style.airdrop}
        >
          <div className={style.airdropBody}>
            <Controller
              control={control}
              name="airdrops.airdropLink"
              rules={{
                required: false,
              }}
              render={({ field: { onChange, name, value } }) => {
                return (
                  <Input
                    onChange={onChange}
                    value={value}
                    big
                    border
                    icon={
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.99992 11.3327H4.66659C2.82564 11.3327 1.33325 9.8403 1.33325 7.99935C1.33325 6.1584 2.82564 4.66602 4.66659 4.66602H5.99992M9.99992 11.3327H11.3333C13.1742 11.3327 14.6666 9.8403 14.6666 7.99935C14.6666 6.1584 13.1742 4.66602 11.3333 4.66602H9.99992M4.66659 7.99935L11.3333 7.99935"
                          stroke="white"
                          stroke-opacity="0.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    }
                    placeholder={"Airdrop link"}
                  />
                );
              }}
            />
            <Text grey>Starting Date</Text>
            <div className={style.row}>
              <Controller
                control={control}
                name="airdrops.airdrop_start_time"
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, name, value } }) => {
                  return (
                    <Select
                      className={style.row__select}
                      onChange={onChange}
                      value={value}
                      big
                      border
                      options={[
                        {
                          name: "Time",
                          value: "",
                        },
                        {
                          name: "10:00",
                          value: "10",
                        },
                        {
                          name: "11:00",
                          value: "11",
                        },
                        {
                          name: "12:00",
                          value: "12",
                        },
                      ]}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="airdrops.airdrop_start_day"
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, name, value } }) => {
                  return (
                    <Select
                      onChange={onChange}
                      value={value}
                      className={style.row__select}
                      big
                      border
                      options={[
                        {
                          name: "Day",
                          value: "",
                        },
                        {
                          name: "1",
                          value: "1",
                        },
                        {
                          name: "2",
                          value: "2",
                        },
                        {
                          name: "3",
                          value: "3",
                        },
                      ]}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="airdrops.airdrop_start_month"
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, name, value } }) => {
                  return (
                    <Select
                      onChange={onChange}
                      value={value}
                      className={style.row__select}
                      big
                      border
                      options={[
                        {
                          name: "Month",
                          value: "",
                        },
                        {
                          name: "January",
                          value: "january",
                        },
                        {
                          name: "February",
                          value: "february",
                        },
                        {
                          name: "March",
                          value: "march",
                        },
                      ]}
                    />
                  );
                }}
              />

              <Controller
                control={control}
                name="airdrops.airdrop_start_year"
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, name, value } }) => {
                  return (
                    <Select
                      onChange={onChange}
                      value={value}
                      className={style.row__select}
                      big
                      border
                      options={[
                        {
                          name: "Year",
                          value: "",
                        },
                        {
                          name: "2024",
                          value: "2024",
                        },
                        {
                          name: "2025",
                          value: "2025",
                        },
                        {
                          name: "2026",
                          value: "2026",
                        },
                      ]}
                    />
                  );
                }}
              />
            </div>
            <Text grey>Expiration Date</Text>
            <div className={style.row}>
              <Controller
                control={control}
                name="airdrops.airdrop_end_time"
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, name, value } }) => {
                  return (
                    <Select
                      className={style.row__select}
                      onChange={onChange}
                      value={value}
                      big
                      border
                      options={[
                        {
                          name: "Time",
                          value: "",
                        },
                        {
                          name: "10:00",
                          value: "10",
                        },
                        {
                          name: "11:00",
                          value: "11",
                        },
                        {
                          name: "12:00",
                          value: "12",
                        },
                      ]}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="airdrops.airdrop_end_day"
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, name, value } }) => {
                  return (
                    <Select
                      onChange={onChange}
                      value={value}
                      className={style.row__select}
                      big
                      border
                      options={[
                        {
                          name: "Day",
                          value: "",
                        },
                        {
                          name: "1",
                          value: "1",
                        },
                        {
                          name: "2",
                          value: "2",
                        },
                        {
                          name: "3",
                          value: "3",
                        },
                      ]}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="airdrops.airdrop_end_month"
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, name, value } }) => {
                  return (
                    <Select
                      onChange={onChange}
                      value={value}
                      className={style.row__select}
                      big
                      border
                      options={[
                        {
                          name: "Month",
                          value: "",
                        },
                        {
                          name: "January",
                          value: "january",
                        },
                        {
                          name: "February",
                          value: "february",
                        },
                        {
                          name: "March",
                          value: "march",
                        },
                      ]}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="airdrops.airdrop_end_year"
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, name, value } }) => {
                  return (
                    <Select
                      onChange={onChange}
                      value={value}
                      className={style.row__select}
                      big
                      border
                      options={[
                        {
                          name: "Year",
                          value: "",
                        },
                        {
                          name: "2024",
                          value: "2024",
                        },
                        {
                          name: "2025",
                          value: "2025",
                        },
                        {
                          name: "2026",
                          value: "2026",
                        },
                      ]}
                    />
                  );
                }}
              />
            </div>
          </div>
        </SubmitCoinCard>
      )}
      {!isShown && (
        <AddCard
          icon={
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.33325 12.6673C2.6764 12.6673 1.33325 11.3242 1.33325 9.66732C1.33325 8.10494 2.52759 6.82151 4.05308 6.68023C4.36513 4.78207 6.01341 3.33398 7.99992 3.33398C9.98642 3.33398 11.6347 4.78207 11.9468 6.68023C13.4722 6.82151 14.6666 8.10494 14.6666 9.66732C14.6666 11.3242 13.3234 12.6673 11.6666 12.6673C8.74003 12.6673 6.89545 12.6673 4.33325 12.6673Z"
                stroke="white"
                stroke-opacity="0.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          }
          title={"Airdrop"}
          onClick={() => setIsShown(true)}
        />
      )}
    </>
  );
};

export default AirdropSection;
