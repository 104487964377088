import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './API';

export const coinsApi = createApi({
  reducerPath: 'coinsApi',
  tagTypes: ['Coins', 'Airdrops'],
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    getAllCoins: builder.query({
      query: () => ({
        url: `/coins/create`,
        method: 'GET',
      }),
      invalidatesTags: ['Coins'],
    }),
    getCoinBySlug: builder.query({
      query: (slug) => ({
        url: `/coins/${slug}`,
        method: 'GET',
      }),
      invalidatesTags: ['Coins'],
    }),
    getCoinsTableByType: builder.query({
      query: ({ type, page }) => ({
        url: `/coins-table/${type}`,
        method: 'GET',
        params: page ? { page } : undefined,
      }),
      invalidatesTags: ['Coins'],
    }),
    voteCoins: builder.mutation({
      query: (coin) => ({
        url: `/coins/${coin}/vote`,
        body: { coin: coin },
        method: 'POST',
      }),
      invalidatesTags: ['Coins', 'Airdrops'],
    }),
    boostCoins: builder.mutation({
      query: (data) => ({
        url: `/coins/${data?.coin}/boost`,
        data: { quantity: 1 },
        method: 'POST',
      }),
      invalidatesTags: ['Coins'],
    }),
    createCoin: builder.mutation({
      query: (data) => ({
        url: `/coins`,
        data: data,
        method: 'POST',
      }),
      invalidatesTags: ['Coins'],
    }),
    uploadImage: builder.mutation({
      query: (data) => ({
        url: `/profile/image?type=${data?.type}&coin_id=${data?.coin_id}`,
        method: 'POST',
        data: data?.formData,
      }),
    }),
    getCoinById: builder.query({
      query: (id) => ({
        url: `/coins/${id}`,
        method: 'GET',
      }),
      invalidatesTags: ['Coins'],
    }),
    editCoin: builder.mutation({
      query: ({ coin_id, payload }) => ({
        url: `/coins/${coin_id}`,
        data: payload,
        method: 'PUT',
      }),
      invalidatesTags: ['Coins'],
    }),
    getEditCoinInfo: builder.query({
      query: (coin_id) => ({
        url: `/coins/${coin_id}/edit`,
        method: 'GET',
      }),
      invalidatesTags: ['Coins'],
    }),
  }),
});

export const {
  useGetAllCoinsQuery,
  useVoteCoinsMutation,
  useGetCoinBySlugQuery,
  useBoostCoinsMutation,
  useCreateCoinMutation,
  useGetCoinsTableByTypeQuery,
  useUploadImageMutation,
  useGetCoinByIdQuery,
  useEditCoinMutation,
  useGetEditCoinInfoQuery,
} = coinsApi;
