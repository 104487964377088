import { useState } from "react";
import { Controller } from "react-hook-form";
import Input from "../../../../components/Input/Input";
import AddCard from "../../components/AddCard/AddCard";
import SubmitCoinCard from "../../modules/Card/Card";
import style from "./Roadmap.module.scss";

const RoadmapSection = ({
  control,
  roadmapFields,
  addNewRoadmap,
  removeRoadmap,
}) => {
  const [show, setShow] = useState(false);
  function onRemove() {
    setShow(false);
    removeRoadmap();
  }

  const predefinedPlaceholders = ["Presale", "Development", "Launch"];

  return (
    <>
      <>
        <AddCard
          className={style.roadmapBody__add}
          onClick={() => {
            addNewRoadmap();
            setShow(true);
          }}
          icon={
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.6667 8.00065C10.6667 9.47341 9.47279 10.6673 8.00003 10.6673C6.52727 10.6673 5.33337 9.47341 5.33337 8.00065M10.6667 8.00065C10.6667 6.52789 9.47279 5.33398 8.00003 5.33398C6.52727 5.33398 5.33337 6.52789 5.33337 8.00065M10.6667 8.00065H14.6667M5.33337 8.00065H1.3335"
                stroke="white"
                stroke-opacity="0.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          }
          title={"Add a Step"}
        />
        {show && (
          <>
            <SubmitCoinCard
              title={"Roadmap"}
              className={style.faq}
              onRemove={onRemove}
            >
              <div className={style.roadmapBody}>
                {roadmapFields?.map((field, index) => (
                  <div
                    className={style.kycBody}
                    key={`roadmap-${field.id}-${index}`}
                  >
                    <Controller
                      control={control}
                      name={`roadmaps.${index}.step`}
                      rules={{
                        required: false,
                      }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Input
                            onChange={onChange}
                            value={value}
                            key={index}
                            border
                            big
                            placeholder={
                              predefinedPlaceholders[index]
                                ? `Step #${index + 1}: ${
                                    predefinedPlaceholders[index]
                                  }`
                                : `Step #${index + 1}`
                            }
                            onRemove={() => removeRoadmap(index)}
                            icon={
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.6667 8.00065C10.6667 9.47341 9.47279 10.6673 8.00003 10.6673C6.52727 10.6673 5.33337 9.47341 5.33337 8.00065M10.6667 8.00065C10.6667 6.52789 9.47279 5.33398 8.00003 5.33398C6.52727 5.33398 5.33337 6.52789 5.33337 8.00065M10.6667 8.00065H14.6667M5.33337 8.00065H1.3335"
                                  stroke="white"
                                  stroke-opacity="0.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            }
                          />
                        );
                      }}
                    />
                  </div>
                ))}
                <AddCard
                  onClick={addNewRoadmap}
                  icon={
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.6667 8.00065C10.6667 9.47341 9.47279 10.6673 8.00003 10.6673C6.52727 10.6673 5.33337 9.47341 5.33337 8.00065M10.6667 8.00065C10.6667 6.52789 9.47279 5.33398 8.00003 5.33398C6.52727 5.33398 5.33337 6.52789 5.33337 8.00065M10.6667 8.00065H14.6667M5.33337 8.00065H1.3335"
                        stroke="white"
                        stroke-opacity="0.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  }
                  title={"Roadmap"}
                />
              </div>
            </SubmitCoinCard>
          </>
        )}
      </>
    </>
  );
};

export default RoadmapSection;
