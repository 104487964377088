import Button from '../../../../components/Button/Button';
import Text from '../../../../components/Text/Text';
import Title from '../../../../components/Title/Title';
import style from './Purchase.module.scss';

import btc from './assets/btc.svg';
import bsc from './assets/bsc.svg';
import tether from './assets/tether.svg';
import eth from './assets/eth.svg';
import PurchaseCard from '../../components/PurchaseCard/PurchaseCard';
import { useEffect, useMemo, useRef, useState } from 'react';
import emptyIcon from './assets/empty-icon.svg';
import { useMediaQuery } from 'usehooks-ts';
import Empty from '../../../../components/Empty/Empty';
import { usePurchaseBoosterMutation } from '../../../../store/boosterApi';

const Purchase = ({ className, cardList, choose, setSelectedCards }) => {
  const sectionRef = useRef(null);
  const isMobile = useMediaQuery('(max-width:991px)');
  const [totalPrice, setTotalPrice] = useState(0);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isBtnVisible, setIsBtnVisible] = useState(false);
  const [confirmBoostPurchase, { isSuccess }] = usePurchaseBoosterMutation();

  useEffect(() => {
    if (isSuccess) {
      setSelectedCards([]);
    }
  }, [isSuccess]);

  const toSection = () => {
    sectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const Visible = function (target) {
    // Все позиции элемента
    const targetPosition = {
        top: window.pageYOffset + target.getBoundingClientRect().top,
        left: window.pageXOffset + target.getBoundingClientRect().left,
        right: window.pageXOffset + target.getBoundingClientRect().right,
        bottom: window.pageYOffset + target.getBoundingClientRect().bottom,
      },
      // Получаем позиции окна
      windowPosition = {
        top: window.pageYOffset,
        left: window.pageXOffset,
        right: window.pageXOffset + document.documentElement.clientWidth,
        bottom: window.pageYOffset + document.documentElement.clientHeight,
      };

    if (
      targetPosition.bottom > windowPosition.top &&
      targetPosition.top < windowPosition.bottom &&
      targetPosition.right > windowPosition.left &&
      targetPosition.left < windowPosition.right
    ) {
      return true;
    } else {
      console.clear();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', function () {
      if (Visible(sectionRef.current)) {
        setIsBtnVisible(false);
      } else {
        setIsBtnVisible(true);
      }
    });
  }, []);

  useEffect(() => {
    setIsEmpty(cardList.length === 0);

    if (!isEmpty) {
      const priceArr = [...cardList.map((item) => item.price)]
        .join('')
        .split('$')
        .map((priceItem) => priceItem.replace(/[\s.,%]/g, ''));

      setTotalPrice(
        priceArr.reduce((prevVal, currentVal) => +prevVal + +currentVal)
      );
    }
  }, [cardList, isEmpty]);

  const selectedCardIds = useMemo(
    () => cardList.map((card) => card.id),
    [cardList]
  );

  return (
    <>
      {isMobile && (
        <Button
          onClick={() => {
            toSection();
          }}
          filled
          className={`${style.toSectionBtn} ${
            isBtnVisible && !isEmpty && style.visible
          }`}
        >
          Confirm Order
          {!isEmpty && <span>({cardList.length} chosen)</span>}
        </Button>
      )}
      <div ref={sectionRef} className={`${style.purchase} ${className}`}>
        {!isEmpty && (
          <>
            <Title.H2 className={style.purchase__title}>My Order</Title.H2>
            <div className={style.purchaseCards}>
              {cardList.map((item, index) => (
                <PurchaseCard
                  id={item.id}
                  key={index}
                  name={item.name}
                  dates={item.dates}
                  price={item.price}
                  choose={choose}
                />
              ))}
            </div>
            <div className={style.purchaseSummary}>
              <Title.H2 className={style.purchaseSummary__title}>
                Summary
              </Title.H2>
              <div className={style.purchaseSummary__row}>
                <Text grey>Total Price</Text>
                <Title.H2>${totalPrice}</Title.H2>
              </div>
              <Button
                className={style.purchaseSummary__btn}
                filled
                onClick={() => {
                  confirmBoostPurchase({ pack_ids: selectedCardIds });
                }}
              >
                Confirm Order
              </Button>
            </div>
          </>
        )}
        {isEmpty && (
          <Empty
            className={style.empty}
            icon={emptyIcon}
            title={
              <>
                So far you haven't <br /> chosen anything
              </>
            }
            text={
              <>
                The more boosts, the higher <br /> you are among other projects
              </>
            }
          />
        )}
        <div className={style.purchasePayment}>
          <Text grey className={style.purchasePayment__text}>
            Payment Methods
          </Text>
          <div className={style.purchasePayment__icons}>
            <img src={btc} alt='' />
            <img src={bsc} alt='' />
            <img src={tether} alt='' />
            <img src={eth} alt='' />
          </div>
        </div>
      </div>
    </>
  );
};

export default Purchase;
