import Text from '../../../../components/Text/Text';
import Title from '../../../../components/Title/Title';
import style from './Content.module.scss';

const Content = ({ id, title, list }) => {
  return (
    <div id={id} className={style.content}>
      <div className={style.contentSide}>
        <Title.H1 className={style.contentSide__title} fz48>
          {title}
        </Title.H1>
      </div>
      <div className={style.contentBody}>
        {list.map((item, index) => (
          <ContentItem key={index} title={item.title} description={item.description} />
        ))}
      </div>
    </div>
  );
};

const ContentItem = ({ title, description }) => {
  return (
    <div className={style.item}>
      <Title.H1 className={style.item__title}>{title}</Title.H1>
      <Text grey fz14 semiBold className={style.item__text}>
        {description}
      </Text>
    </div>
  );
};

export default Content;
