import { useEffect, useState } from 'react';
import Input from '../../../../components/Input/Input';
import Text from '../../../../components/Text/Text';
import TokenInfo from '../../../../components/TokenInfo/TokenInfo';
import SubmitCoinCard from '../../../SubmitCoin/modules/Card/Card';
import style from './SelectListing.module.scss';
import icon from './assets/icon.jpg';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllCoins,
  resetCoinsList,
} from '../../../../store/searchCoinsSlice';
import { useCopyToClipboard } from 'usehooks-ts';

const SelectListing = (props) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [copiedText, copy] = useCopyToClipboard();
  const dispatch = useDispatch();

  const openSearch = () => {
    setIsSearchOpen(true);
  };
  const closeSearch = () => {
    setIsSearchOpen(false);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchValue.length > 2) {
        dispatch(getAllCoins(searchValue));
      } else {
        dispatch(resetCoinsList());
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue, dispatch]);

  const coinsList = useSelector((state) => state?.coins?.coins?.list);

  return (
    <SubmitCoinCard className={style.select} title={'Select the listing'}>
      <div className={style.selectSearch}>
        <Input
          border
          big
          onFocus={openSearch}
          icon={
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M14 14L11.1 11.1M12.6667 7.33333C12.6667 10.2789 10.2789 12.6667 7.33333 12.6667C4.38781 12.6667 2 10.2789 2 7.33333C2 4.38781 4.38781 2 7.33333 2C10.2789 2 12.6667 4.38781 12.6667 7.33333Z'
                stroke='white'
                stroke-opacity='0.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          }
          placeholder={'Search for a project'}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <div
          className={`${style.selectSearch__dropdown} ${style.results} ${
            isSearchOpen && style.open
          }`}
        >
          <div className={style.resultsContent}>
            {coinsList?.coins?.length === 0 ? (
              <div style={{ color: 'white' }}>No results found</div>
            ) : (
              coinsList?.coins?.map((item, index) => {
                return (
                  <DropdownItem
                    icon={icon}
                    name={item?.title}
                    type={'Meme'}
                    address={`${item?.address.slice(
                      0,
                      6
                    )}...${item?.address.slice(-6)}`}
                    key={index}
                    item={item}
                    closeSearch={closeSearch}
                    appendListing={props.appendListing}
                    copy={copy}
                  />
                );
              })
            )}
          </div>
        </div>
      </div>
      <div className={style.selectList}>
        {props?.listingFields?.map((item, index) => {
          return (
            <ListItem
              icon={icon}
              title={'Memereum'}
              removeListing={props.removeListing}
              index={index}
            />
          );
        })}
      </div>
    </SubmitCoinCard>
  );
};

const ListItem = ({ icon, title, removeListing, index }) => {
  return (
    <div className={style.item}>
      <div className={style.item__icon}>
        <img src={icon} alt='' />
      </div>
      <Text className={style.item__title}>{title}</Text>
      <button
        className={style.item__close}
        onClick={() => removeListing(index)}
      >
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M12 4L4 12M4 4L12 12'
            stroke='white'
            stroke-opacity='0.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
      </button>
    </div>
  );
};

const DropdownItem = ({
  icon,
  name,
  type,
  address,
  item,
  closeSearch,
  appendListing,
  copy,
}) => {
  return (
    <div
      className={style.dropdownItem}
      onClick={() => {
        appendListing(item);
        closeSearch();
      }}
    >
      <TokenInfo icon={icon} name={name} label={type} />
      <div className={style.rowAddress}>
        <Text className={style.rowAddress__network} grey>
          BSC
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z'
              fill='white'
              fill-opacity='0.1'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M10.0755 8.8465L11.2822 10.0498L8.00179 13.3268L4.72481 10.0498L5.93157 8.8465L8.00179 10.9167L10.0755 8.8465ZM8.00179 6.77281L9.22589 7.99691L8.00179 9.22101L6.78116 8.00037V7.99691L6.99616 7.78191L7.10019 7.67788L8.00179 6.77281ZM3.87522 6.79015L5.08198 7.99691L3.87522 9.2002L2.66846 7.99344L3.87522 6.79015ZM12.1284 6.79015L13.3351 7.99691L12.1284 9.2002L10.9216 7.99344L12.1284 6.79015ZM8.00179 2.66357L11.2788 5.94056L10.072 7.14732L8.00179 5.07363L5.93157 7.14385L4.72481 5.94056L8.00179 2.66357Z'
              fill='#FFC700'
            />
          </svg>
        </Text>
        <Text className={style.rowAddress__item} grey>
          <span>{address}</span>
          <button onClick={() => copy(item?.address)}>
            <svg
              width='12'
              height='12'
              viewBox='0 0 12 12'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M3.75 1.5H7.3C8.42011 1.5 8.98016 1.5 9.40798 1.71799C9.78431 1.90973 10.0903 2.21569 10.282 2.59202C10.5 3.01984 10.5 3.5799 10.5 4.7V8.25M3.1 10.5H7.15C7.71005 10.5 7.99008 10.5 8.20399 10.391C8.39215 10.2951 8.54513 10.1422 8.64101 9.95399C8.75 9.74008 8.75 9.46005 8.75 8.9V4.85C8.75 4.28995 8.75 4.00992 8.64101 3.79601C8.54513 3.60785 8.39215 3.45487 8.20399 3.35899C7.99008 3.25 7.71005 3.25 7.15 3.25H3.1C2.53995 3.25 2.25992 3.25 2.04601 3.35899C1.85785 3.45487 1.70487 3.60785 1.60899 3.79601C1.5 4.00992 1.5 4.28995 1.5 4.85V8.9C1.5 9.46005 1.5 9.74008 1.60899 9.95399C1.70487 10.1422 1.85785 10.2951 2.04601 10.391C2.25992 10.5 2.53995 10.5 3.1 10.5Z'
                stroke='white'
                stroke-opacity='0.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </button>
        </Text>
      </div>
    </div>
  );
};

export default SelectListing;
