import { configureStore } from '@reduxjs/toolkit';
import { airdropsApi } from './airdropsApi';
import { authApi } from './authApi';
import { blogApi } from './blogApi';
import { homeApi } from './homeApi';
import { coinsApi } from './coinsApi';
import { watchlistApi } from './watchlistApi';
import { dictionariesApi } from './dictionariesApi';
import { promotionsApi } from './promotionsApi';
import coinsSlice from './searchCoinsSlice';
import { boosterApi } from './boosterApi';
import { presalesApi } from './presales.Api';

export const store = configureStore({
  reducer: {
    [dictionariesApi.reducerPath]: dictionariesApi.reducer,
    [promotionsApi.reducerPath]: promotionsApi.reducer,
    [watchlistApi.reducerPath]: watchlistApi.reducer,
    [airdropsApi.reducerPath]: airdropsApi.reducer,
    [presalesApi.reducerPath]: presalesApi.reducer,
    [boosterApi.reducerPath]: boosterApi.reducer,
    [coinsApi.reducerPath]: coinsApi.reducer,
    [blogApi.reducerPath]: blogApi.reducer,
    [homeApi.reducerPath]: homeApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    coins: coinsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      dictionariesApi.middleware,
      promotionsApi.middleware,
      watchlistApi.middleware,
      airdropsApi.middleware,
      presalesApi.middleware,
      boosterApi.middleware,
      coinsApi.middleware,
      homeApi.middleware,
      blogApi.middleware,
      authApi.middleware,
    ]),
});
