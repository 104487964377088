import { useRef, useState } from 'react';
import style from './Input.module.scss';
import Text from '../Text/Text';
import { useCopyToClipboard } from 'usehooks-ts';

const Input = ({
  className,
  placeholder,
  icon,
  type = 'text',
  withClear,
  withCopy,
  onRemove,
  onClick,
  big,
  border,
  info,
  value,
  label,
  disabled,
  onChange,
  dark,
  fileUpload,
  imageUpload,
  password,
  defaultValue,
  onFocus,
  onBlur,
  removeField,
}) => {
  const inputRef = useRef(null);
  const [isClearVisible, setIsClearVisible] = useState(false);
  const [isLabelVisible, setIsLabelVisible] = useState(false);
  const [copiedText, copy] = useCopyToClipboard();
  const [fileName, setFileName] = useState('');
  const [isPassVisible, setIsPassVisible] = useState(false);

  const handleInputClear = () => {
    if (inputRef.current.value.length > 0) {
      setIsClearVisible(true);
    } else {
      setIsClearVisible(false);
    }
  };
  const handleInputLabel = () => {
    if (inputRef.current.value.length > 0) {
      setIsLabelVisible(true);
    } else {
      setIsLabelVisible(false);
    }
  };

  const clearInput = () => {
    inputRef.current.value = '';
    setIsClearVisible(false);
  };
  const copyInput = () => {
    copy(inputRef.current.value)
      .then(() => {
        console.log('Copied!', { inputRef });
      })
      .catch((error) => {
        console.error('Failed to copy!', error);
      });
  };

  function handleChange({ target }) {
    const fileNames = Array.from(target.files).map((file) => file.name);
    setFileName(fileNames);
  }
  function handleVisible() {
    setIsPassVisible(!isPassVisible);
  }

  const removeFile = (e) => {
    // e.stopPropagation();
    inputRef.current.value = null;
    setFileName('');
    removeField();
  };

  return (
    <label
      onClick={onClick}
      className={`${style.input} 
      ${big && style.big}
      ${border && style.border}
      ${dark && style.dark}
      ${fileUpload && style.file}
      ${imageUpload && style.image}
      ${disabled && style.disabled}
       ${className}`}
    >
      {icon && <div className={style.input__icon}>{icon}</div>}
      {label && (
        <Text
          grey
          className={`${style.input__label} ${isLabelVisible && style.visible}`}
        >
          {label}
        </Text>
      )}
      <input
        ref={inputRef}
        type={
          fileUpload || imageUpload
            ? 'file'
            : password
            ? isPassVisible
              ? 'text'
              : 'password'
            : type
        }
        onChange={(event) => {
          onChange && onChange(event);
          (fileUpload || imageUpload) && handleChange(event);
        }}
        onInput={() => {
          withClear && handleInputClear();
          label && handleInputLabel();
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        defaultValue={defaultValue}
        disabled={disabled}
        value={value}
      />
      {fileUpload && (
        <div className={style.inputFile}>
          <Text grey={fileName.length === 0}>
            {fileName ? fileName : 'Add document'}
          </Text>
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M7.99992 3.33203V12.6654M3.33325 7.9987H12.6666'
              stroke='white'
              stroke-opacity='0.5'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </div>
      )}
      {imageUpload && (
        <div className={style.inputFile}>
          <Text grey={fileName.length === 0}>
           Add image
          </Text>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M14 10V10.8C14 11.9201 14 12.4802 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.4802 14 11.9201 14 10.8 14H5.2C4.07989 14 3.51984 14 3.09202 13.782C2.71569 13.5903 2.40973 13.2843 2.21799 12.908C2 12.4802 2 11.9201 2 10.8V10M11.3333 5.33333L8 2M8 2L4.66667 5.33333M8 2V10'
                stroke='white'
                stroke-opacity='0.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
        </div>
      )}
      {withClear && (
        <button
          onClick={clearInput}
          className={`${style.input__clear} ${isClearVisible && style.visible}`}
        >
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M12 4L4 12M4 4L12 12'
              stroke='white'
              stroke-opacity='0.5'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </button>
      )}
      {withCopy && (
        <button onClick={copyInput} className={`${style.input__info}`}>
          <svg
            width='12'
            height='12'
            viewBox='0 0 12 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M3.75 1.5H7.3C8.42011 1.5 8.98016 1.5 9.40798 1.71799C9.78431 1.90973 10.0903 2.21569 10.282 2.59202C10.5 3.01984 10.5 3.5799 10.5 4.7V8.25M3.1 10.5H7.15C7.71005 10.5 7.99008 10.5 8.20399 10.391C8.39215 10.2951 8.54513 10.1422 8.64101 9.95399C8.75 9.74008 8.75 9.46005 8.75 8.9V4.85C8.75 4.28995 8.75 4.00992 8.64101 3.79601C8.54513 3.60785 8.39215 3.45487 8.20399 3.35899C7.99008 3.25 7.71005 3.25 7.15 3.25H3.1C2.53995 3.25 2.25992 3.25 2.04601 3.35899C1.85785 3.45487 1.70487 3.60785 1.60899 3.79601C1.5 4.00992 1.5 4.28995 1.5 4.85V8.9C1.5 9.46005 1.5 9.74008 1.60899 9.95399C1.70487 10.1422 1.85785 10.2951 2.04601 10.391C2.25992 10.5 2.53995 10.5 3.1 10.5Z'
              stroke='#F9FCFF'
              stroke-opacity='0.5'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </button>
      )}
      {password && (
        <button onClick={handleVisible} className={`${style.input__info}`}>
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M7.16196 3.39488C7.4329 3.35482 7.7124 3.33333 8.00028 3.33333C11.4036 3.33333 13.6369 6.33656 14.3871 7.52455C14.4779 7.66833 14.5233 7.74023 14.5488 7.85112C14.5678 7.93439 14.5678 8.06578 14.5487 8.14905C14.5233 8.25993 14.4776 8.3323 14.3861 8.47705C14.1862 8.79343 13.8814 9.23807 13.4777 9.7203M4.48288 4.47669C3.0415 5.45447 2.06297 6.81292 1.61407 7.52352C1.52286 7.66791 1.47725 7.74011 1.45183 7.85099C1.43273 7.93426 1.43272 8.06563 1.45181 8.14891C1.47722 8.25979 1.52262 8.33168 1.61342 8.47545C2.36369 9.66344 4.59694 12.6667 8.00028 12.6667C9.37255 12.6667 10.5546 12.1784 11.5259 11.5177M2.00028 2L14.0003 14M6.58606 6.58579C6.22413 6.94772 6.00028 7.44772 6.00028 8C6.00028 9.10457 6.89571 10 8.00028 10C8.55256 10 9.05256 9.77614 9.41449 9.41421'
              stroke='white'
              stroke-opacity='0.5'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </button>
      )}
      {onRemove && (
        <button onClick={onRemove} className={`${style.input__info} `}>
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M10.6667 4.00065V3.46732C10.6667 2.72058 10.6667 2.34721 10.5213 2.062C10.3935 1.81111 10.1895 1.60714 9.93865 1.47931C9.65344 1.33398 9.28007 1.33398 8.53333 1.33398H7.46667C6.71993 1.33398 6.34656 1.33398 6.06135 1.47931C5.81046 1.60714 5.60649 1.81111 5.47866 2.062C5.33333 2.34721 5.33333 2.72058 5.33333 3.46732V4.00065M2 4.00065H14M12.6667 4.00065V11.4673C12.6667 12.5874 12.6667 13.1475 12.4487 13.5753C12.2569 13.9516 11.951 14.2576 11.5746 14.4493C11.1468 14.6673 10.5868 14.6673 9.46667 14.6673H6.53333C5.41323 14.6673 4.85318 14.6673 4.42535 14.4493C4.04903 14.2576 3.74307 13.9516 3.55132 13.5753C3.33333 13.1475 3.33333 12.5874 3.33333 11.4673V4.00065'
              stroke='white'
              stroke-opacity='0.5'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </button>
      )}
      {info && (
        <button onClick={clearInput} className={`${style.input__info} `}>
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g>
              <path
                d='M7.99992 10.6663V7.99967M7.99992 5.33301H8.00659M14.6666 7.99967C14.6666 11.6816 11.6818 14.6663 7.99992 14.6663C4.31802 14.6663 1.33325 11.6816 1.33325 7.99967C1.33325 4.31778 4.31802 1.33301 7.99992 1.33301C11.6818 1.33301 14.6666 4.31778 14.6666 7.99967Z'
                stroke='white'
                stroke-opacity='0.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </g>
          </svg>
        </button>
      )}
    </label>
  );
};

export default Input;
