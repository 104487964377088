// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panels-slider-pagination span.swiper-pagination-bullet-active {
  background: rgb(255, 255, 255);
}`, "",{"version":3,"sources":["webpack://./src/pages/Main/modules/Panels/slider.scss"],"names":[],"mappings":"AAEI;EACE,8BAAA;AADN","sourcesContent":[".panels-slider-pagination {\n  span {\n    &.swiper-pagination-bullet-active {\n      background: rgba(255, 255, 255, 1);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
