import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const UseGeneralHooks = (dateString) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
      };

      const navigate = useNavigate();
      const params = useParams();

      function copyToClipboard(text) {
        if (navigator.clipboard) {
          navigator.clipboard.writeText(text)
            .then(() => {
              console.log('Text copied to clipboard');
            })
            .catch(err => {
              console.error('Failed to copy text: ', err);
            });
        } else {
          console.error('Clipboard API not supported');
        }
      }

      const handleShare = (platform) => {
        const url = "https://github.com";
        const text = "Check out this GitHub repository!";
        
        let shareUrl = '';
        
        if (platform === 'twitter') {
          shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;
        } else if (platform === 'discord') {
          shareUrl = `https://discord.com/channels/@me?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;
        }
    
        if (shareUrl) {
          window.open(shareUrl, '_blank', 'noopener,noreferrer');
        }
      };

  return {
    formatDate,
    navigate,
    params,
    copyToClipboard,
    handleShare
  };
};

export default UseGeneralHooks