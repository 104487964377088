import Text from "../../../../components/Text/Text";
import Title from "../../../../components/Title/Title";
import UseGeneralHooks from "../../../../hooks/useGeneralHooks";
import style from "./Top.module.scss";

const SubmitCoinTop = () => {
  const { params } = UseGeneralHooks();
  return (
    <div className={style.top}>
      <div className="__container">
        <div className={style.topBody}>
          <Title.H1 center className={style.topBody__title}>
            {params?.id ? "Edit Coin" : "Submit Coin"}
          </Title.H1>
          <Text grey center className={style.topBody__text}>
            Please enter the minimum <br /> information to publish the coin.
          </Text>
        </div>
      </div>
    </div>
  );
};

export default SubmitCoinTop;
