import { useState } from 'react';
import LoginForm from '../../Forms/loginForm';
import RegisterationForm from '../../Forms/registerationForm';
import Button from '../../Button/Button';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import Text from '../../Text/Text';
import Title from '../../Title/Title';
import style from './Registration.module.scss';

const RegistrationModal = ({ close, openForgotPasswordModal }) => {
  const [isSwitched, setIsSwitched] = useState(false);

  return (
    <ModalWrapper closeModal={close}>
      <div className={style.modal}>
        <div className={style.modal__switch}>
          <Button
            onClick={() => setIsSwitched(false)}
            className={style.modal__switchBtn}
            noBorder={isSwitched}
            filled={!isSwitched}
          >
            Registration
          </Button>
          <Button
            onClick={() => setIsSwitched(true)}
            className={style.modal__switchBtn}
            noBorder={!isSwitched}
            filled={isSwitched}
          >
            Log in
          </Button>
        </div>
        {isSwitched ? (
          <>
            <Title.H1 center className={style.modal__title}>
              Log in
            </Title.H1>
            <Text grey center>
              Fill out the information in the fields below <br /> to log into
              your account
            </Text>
            <LoginForm
              handleClose={close}
              openForgotPasswordModal={openForgotPasswordModal}
            />
          </>
        ) : (
          <>
            <Title.H1 center className={style.modal__title}>
              Register
            </Title.H1>
            <Text grey center>
              Create your account at Coingems <br /> to unlock new features
            </Text>
            <RegisterationForm handleClose={close} />
            <Text grey center>
              By clicking this button, you automatically agree to the <br />{' '}
              terms and conditions of the site
            </Text>
          </>
        )}
        <Text grey center>
          or
        </Text>
        <div className={style.modalSocial}>
          <Button onlyIcon>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g clip-path='url(#clip0_886_41110)'>
                <path
                  d='M15.04 8.16699C15.04 7.64699 14.9933 7.14699 14.9067 6.66699H8V9.50699H11.9467C11.7733 10.4203 11.2533 11.1937 10.4733 11.7137V13.5603H12.8533C14.24 12.2803 15.04 10.4003 15.04 8.16699Z'
                  fill='white'
                />
                <path
                  d='M8.00004 15.3337C9.98004 15.3337 11.64 14.6804 12.8534 13.5604L10.4734 11.7137C9.82004 12.1537 8.9867 12.4204 8.00004 12.4204C6.09337 12.4204 4.47337 11.1337 3.89337 9.40039H1.45337V11.2937C2.66004 13.6871 5.13337 15.3337 8.00004 15.3337Z'
                  fill='white'
                />
                <path
                  d='M3.89341 9.39289C3.74675 8.95289 3.66008 8.48622 3.66008 7.99956C3.66008 7.51289 3.74675 7.04622 3.89341 6.60622V4.71289H1.45341C0.953415 5.69956 0.666748 6.81289 0.666748 7.99956C0.666748 9.18622 0.953415 10.2996 1.45341 11.2862L3.35341 9.80622L3.89341 9.39289Z'
                  fill='white'
                />
                <path
                  d='M8.00004 3.58699C9.08004 3.58699 10.04 3.96033 10.8067 4.68033L12.9067 2.58033C11.6334 1.39366 9.98004 0.666992 8.00004 0.666992C5.13337 0.666992 2.66004 2.31366 1.45337 4.71366L3.89337 6.60699C4.47337 4.87366 6.09337 3.58699 8.00004 3.58699Z'
                  fill='white'
                />
              </g>
              <defs>
                <clipPath id='clip0_886_41110'>
                  <rect width='16' height='16' fill='white' />
                </clipPath>
              </defs>
            </svg>
          </Button>
          <Button onlyIcon>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g clip-path='url(#clip0_886_41116)'>
                <path
                  d='M12.5867 0H15.0401L9.65341 6.78967L15.9467 16H11.0081L7.14141 10.403L2.71474 16H0.261406L5.96807 8.73801L-0.0585938 0H5.00274L8.49607 5.11292L12.5867 0ZM11.7281 14.4059H13.0881L4.28807 1.53506H2.82674L11.7281 14.4059Z'
                  fill='white'
                />
              </g>
              <defs>
                <clipPath id='clip0_886_41116'>
                  <rect width='16' height='16' fill='white' />
                </clipPath>
              </defs>
            </svg>
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default RegistrationModal;
