import Button from "../../Button/Button";
import ModalWrapper from "../../ModalWrapper/ModalWrapper";
import Text from "../../Text/Text";
import Title from "../../Title/Title";
import style from "./Share.module.scss";
import icon from "./assets/icon.svg";
import UseGeneralHooks from "../../../hooks/useGeneralHooks";

const ShareModal = ({ close }) => {
  const { copyToClipboard, handleShare } = UseGeneralHooks();
  return (
    <ModalWrapper closeModal={close}>
      <div className={style.modal}>
        <div className={style.modal__icon}>
          <img src={icon} alt="" />
        </div>
        <Title.H1 center className={style.modal__title}>
          Share the list
        </Title.H1>
        <Text grey center>
          You can share your list <br /> of favorite projects
        </Text>
        <div className={style.modalShare}>
          <Button className={style.modalShare__item} onClick={() => {handleShare("discord"); close()}}>
            <div className={style.modalShare__itemIcon}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5535 2.81854C12.5178 2.33396 11.4104 1.98178 10.2526 1.78125C10.1104 2.03832 9.94429 2.38409 9.82976 2.65915C8.599 2.47406 7.37956 2.47406 6.17144 2.65915C6.05693 2.38409 5.88704 2.03832 5.74357 1.78125C4.58454 1.98178 3.47584 2.33525 2.44013 2.8211C0.351095 5.97791 -0.215207 9.0563 0.0679444 12.091C1.4535 13.1257 2.79627 13.7542 4.11638 14.1655C4.44233 13.7169 4.73302 13.2401 4.98345 12.7375C4.5065 12.5563 4.04969 12.3326 3.61805 12.073C3.73256 11.9882 3.84457 11.8995 3.95279 11.8082C6.58546 13.0396 9.44593 13.0396 12.0472 11.8082C12.1566 11.8995 12.2686 11.9882 12.3819 12.073C11.949 12.3339 11.4909 12.5576 11.014 12.7388C11.2644 13.2401 11.5538 13.7182 11.881 14.1668C13.2024 13.7555 14.5464 13.127 15.932 12.091C16.2642 8.57301 15.3644 5.52289 13.5535 2.81854ZM5.34212 10.2247C4.55181 10.2247 3.9037 9.48688 3.9037 8.58843C3.9037 7.68998 4.53797 6.95091 5.34212 6.95091C6.14628 6.95091 6.79437 7.68868 6.78053 8.58843C6.78178 9.48688 6.14628 10.2247 5.34212 10.2247ZM10.6578 10.2247C9.86752 10.2247 9.21941 9.48688 9.21941 8.58843C9.21941 7.68998 9.85366 6.95091 10.6578 6.95091C11.462 6.95091 12.1101 7.68868 12.0962 8.58843C12.0962 9.48688 11.462 10.2247 10.6578 10.2247Z"
                  fill="white"
                />
              </svg>
            </div>
            <Text grey>Discord</Text>
          </Button>
          <Button className={style.modalShare__item} onClick={() => {handleShare("twitter"); close()}}>
            <div className={style.modalShare__itemIcon}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_400_34381)">
                  <path
                    d="M12.5867 0H15.0401L9.65341 6.78967L15.9467 16H11.0081L7.14141 10.403L2.71474 16H0.261406L5.96807 8.73801L-0.0585938 0H5.00274L8.49607 5.11292L12.5867 0ZM11.7281 14.4059H13.0881L4.28807 1.53506H2.82674L11.7281 14.4059Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_400_34381">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <Text grey>Twitter</Text>
          </Button>
          <Button
            className={style.modalShare__item}
            onClick={() => copyToClipboard(window?.location?.href)}
          >
            <div className={style.modalShare__itemIcon}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_400_34388)">
                  <path
                    d="M3.3335 9.99998C2.71224 9.99998 2.40161 9.99998 2.15658 9.89849C1.82988 9.76316 1.57032 9.50359 1.43499 9.17689C1.3335 8.93186 1.3335 8.62124 1.3335 7.99998V3.46665C1.3335 2.71991 1.3335 2.34654 1.47882 2.06133C1.60665 1.81044 1.81063 1.60647 2.06151 1.47864C2.34672 1.33331 2.72009 1.33331 3.46683 1.33331H8.00016C8.62142 1.33331 8.93205 1.33331 9.17707 1.43481C9.50378 1.57013 9.76334 1.8297 9.89867 2.1564C10.0002 2.40143 10.0002 2.71206 10.0002 3.33331M8.1335 14.6666H12.5335C13.2802 14.6666 13.6536 14.6666 13.9388 14.5213C14.1897 14.3935 14.3937 14.1895 14.5215 13.9386C14.6668 13.6534 14.6668 13.28 14.6668 12.5333V8.13331C14.6668 7.38658 14.6668 7.01321 14.5215 6.72799C14.3937 6.47711 14.1897 6.27314 13.9388 6.1453C13.6536 5.99998 13.2802 5.99998 12.5335 5.99998H8.1335C7.38676 5.99998 7.01339 5.99998 6.72818 6.1453C6.47729 6.27314 6.27332 6.47711 6.14549 6.72799C6.00016 7.01321 6.00016 7.38658 6.00016 8.13331V12.5333C6.00016 13.28 6.00016 13.6534 6.14549 13.9386C6.27332 14.1895 6.47729 14.3935 6.72818 14.5213C7.01339 14.6666 7.38676 14.6666 8.1335 14.6666Z"
                    stroke="white"
                    stroke-opacity="0.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_400_34388">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <Text grey>Copy</Text>
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ShareModal;
