import Text from '../../../../components/Text/Text';
import Title from '../../../../components/Title/Title';
import style from './PurchaseCard.module.scss';

const PurchaseCard = ({ id, name, dates, price, choose }) => {

  return (
    <div className={style.card}>
      <div className={style.cardInfo}>
        <Title.H2>{name}</Title.H2>
        <Text grey>{dates}</Text>
      </div>

      <Title.H2 className={style.card__price}>{price}</Title.H2>
      <div className={style.cardBtns}>
        <button
          onClick={() => choose({ id, price }, 'remove')}
          className={style.card__remove}
        >
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M7.99998 13.3322H14M2 13.3322H3.11636C3.44248 13.3322 3.60554 13.3322 3.75899 13.2953C3.89504 13.2627 4.0251 13.2088 4.1444 13.1357C4.27895 13.0532 4.39425 12.9379 4.62486 12.7073L13 4.33218C13.5523 3.7799 13.5523 2.88447 13 2.33218C12.4477 1.7799 11.5523 1.7799 11 2.33218L2.62484 10.7073C2.39424 10.9379 2.27894 11.0532 2.19648 11.1878C2.12338 11.3071 2.0695 11.4372 2.03684 11.5732C2 11.7267 2 11.8897 2 12.2158V13.3322Z'
              stroke='white'
              stroke-opacity='0.5'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </button>
        <button
          onClick={() => choose({ id, price }, 'remove')}
          className={style.card__remove}
        >
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M12 4L4 12M4 4L12 12'
              stroke='white'
              stroke-opacity='0.5'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default PurchaseCard;
