import style from './Top.module.scss';
import Button from '../../../../components/Button/Button';
import { useEffect, useState } from 'react';
import UseGeneralHooks from '../../../../hooks/useGeneralHooks';
import {
  useAttachWatchlistMutation,
  useDeleteWatchlistMutation,
} from '../../../../store/watchlistApi';
import ShareModal from '../../../../components/modals/Share/Share';

const ProjectTop = ({ coin, can_be_updated }) => {
  const [isFavorite, setIsFavorite] = useState(true);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const { navigate, params } = UseGeneralHooks();
  const [attachToWatchlist, { isSuccess: attachSuccess }] =
    useAttachWatchlistMutation();
  const [detachWatchlist, { isSuccess: detachSuccess }] =
    useDeleteWatchlistMutation();

  useEffect(() => {
    if (attachSuccess || detachSuccess) {
      setIsFavorite(!isFavorite);
    }
  }, [attachSuccess, detachSuccess]);

  return (
    <section className={style.project}>
      <div className='__container'>
        <div className={style.projectBody}>
          <Button
            onlyIcon
            lightYellow={isFavorite}
            className={`${style.favorite} ${isFavorite && style.enable}`}
            onClick={() => {
              coin?.is_wishlisted
                ? detachWatchlist(coin?.id)
                : attachToWatchlist(coin?.id);
            }}
          >
            <svg
              width='14'
              height='12'
              viewBox='0 0 14 12'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M6.30562 0.549118C6.45928 0.237826 6.53611 0.0821792 6.64041 0.0324501C6.73115 -0.0108167 6.83658 -0.0108167 6.92732 0.0324501C7.03162 0.0821792 7.10845 0.237826 7.26211 0.549118L8.71989 3.50243C8.76526 3.59433 8.78794 3.64028 8.82109 3.67596C8.85044 3.70755 8.88563 3.73314 8.92473 3.75132C8.96889 3.77186 9.01959 3.77927 9.121 3.79409L12.3818 4.27071C12.7252 4.3209 12.8969 4.346 12.9764 4.42987C13.0455 4.50284 13.078 4.60311 13.0649 4.70276C13.0497 4.81729 12.9254 4.93836 12.6768 5.1805L10.3182 7.47785C10.2446 7.54947 10.2079 7.58528 10.1841 7.62788C10.1631 7.66561 10.1497 7.70705 10.1445 7.74991C10.1386 7.79832 10.1473 7.8489 10.1646 7.95007L10.7212 11.195C10.7799 11.5372 10.8092 11.7084 10.7541 11.8099C10.7061 11.8983 10.6208 11.9602 10.5219 11.9786C10.4083 11.9996 10.2546 11.9188 9.94726 11.7572L7.03211 10.2241C6.94128 10.1764 6.89586 10.1525 6.84802 10.1431C6.80565 10.1348 6.76208 10.1348 6.71972 10.1431C6.67187 10.1525 6.62645 10.1764 6.53562 10.2241L3.62047 11.7572C3.31313 11.9188 3.15946 11.9996 3.04584 11.9786C2.94698 11.9602 2.86167 11.8983 2.81368 11.8099C2.75852 11.7084 2.78787 11.5372 2.84657 11.195L3.40311 7.95007C3.42046 7.8489 3.42914 7.79832 3.42327 7.74991C3.41807 7.70705 3.4046 7.66561 3.38359 7.62788C3.35987 7.58528 3.32311 7.54947 3.24958 7.47785L0.890894 5.18049C0.642296 4.93836 0.517997 4.81729 0.502872 4.70276C0.489712 4.60311 0.522223 4.50284 0.591355 4.42987C0.670811 4.346 0.842502 4.3209 1.18588 4.27071L4.44673 3.79409C4.54814 3.77927 4.59884 3.77186 4.643 3.75132C4.6821 3.73314 4.7173 3.70755 4.74664 3.67596C4.77979 3.64028 4.80247 3.59433 4.84784 3.50243L6.30562 0.549118Z'
                fill='#F09A1A'
              />
            </svg>
          </Button>
          <Button onlyIcon onClick={() => setShareModalOpen(true)}>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M5.72667 9.00536L10.28 11.6587M10.2733 4.3387L5.72667 6.99203M14 3.33203C14 4.4366 13.1046 5.33203 12 5.33203C10.8954 5.33203 10 4.4366 10 3.33203C10 2.22746 10.8954 1.33203 12 1.33203C13.1046 1.33203 14 2.22746 14 3.33203ZM6 7.9987C6 9.10327 5.10457 9.9987 4 9.9987C2.89543 9.9987 2 9.10327 2 7.9987C2 6.89413 2.89543 5.9987 4 5.9987C5.10457 5.9987 6 6.89413 6 7.9987ZM14 12.6654C14 13.7699 13.1046 14.6654 12 14.6654C10.8954 14.6654 10 13.7699 10 12.6654C10 11.5608 10.8954 10.6654 12 10.6654C13.1046 10.6654 14 11.5608 14 12.6654Z'
                stroke='white'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </Button>
          <Button onlyIcon onClick={() => navigate(`/report-listing`)}>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M2.66675 9.9987C2.66675 9.9987 3.33341 9.33203 5.33341 9.33203C7.33341 9.33203 8.66675 10.6654 10.6667 10.6654C12.6667 10.6654 13.3334 9.9987 13.3334 9.9987V1.9987C13.3334 1.9987 12.6667 2.66536 10.6667 2.66536C8.66675 2.66536 7.33341 1.33203 5.33341 1.33203C3.33341 1.33203 2.66675 1.9987 2.66675 1.9987L2.66675 14.6654'
                stroke='white'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </Button>
          {can_be_updated && (
            <Button
              onClick={() => {
                navigate(`/submit_coin/${params?.id}`);
              }}
            >
              Update Project
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M14 10V10.8C14 11.9201 14 12.4802 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.4802 14 11.9201 14 10.8 14H5.2C4.07989 14 3.51984 14 3.09202 13.782C2.71569 13.5903 2.40973 13.2843 2.21799 12.908C2 12.4802 2 11.9201 2 10.8V10M11.3333 5.33333L8 2M8 2L4.66667 5.33333M8 2V10'
                  stroke='white'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </Button>
          )}
        </div>
      </div>
      {shareModalOpen && <ShareModal close={() => setShareModalOpen(false)} />}
    </section>
  );
};

export default ProjectTop;
