import TokenInfo from '../../../../components/TokenInfo/TokenInfo';
import PanelCard, {
  PanelCardNews,
  PanelCardRow,
  PanelCardTags,
  PanelCardValue,
} from '../../components/PanelCard/PanelCard';
import Panels from '../../modules/Panels/Panels';
import style from './PanelsSection.module.scss';
import img from '../PromotedSections/assets/card-icon.jpg';
import img2 from '../PromotedSections/assets/card-icon-2.jpg';

const PanelsSection = ({ blocks }) => {
  return (
    <div className={style.panels}>
      <Panels>
        <PanelCard className={style.panels__item} yellow title='Trending Coins'>
          {blocks?.trendingCoins?.list?.map((coin, index) => {
            return (
              <PanelCardRow key={index}>
                <TokenInfo
                  icon={img}
                  name={coin?.title ? coin?.title : '-'}
                  label={coin?.current_price && `${coin?.current_price} $`}
                />
                <PanelCardValue value={coin?.status} />
              </PanelCardRow>
            );
          })}
        </PanelCard>
        <PanelCard className={style.panels__item} title='New Coins'>
          {blocks?.newCoins?.list?.map((coin, index) => {
            return (
              <PanelCardRow key={index}>
                <TokenInfo
                  icon={img}
                  name={coin?.title ? coin?.title : '-'}
                  label={coin?.current_price && `${coin?.current_price} $`}
                />
                <PanelCardValue value={coin?.status} />
              </PanelCardRow>
            );
          })}
        </PanelCard>
        <PanelCard className={style.panels__item} blue title='Popular Presales'>
          {blocks?.popularPresales?.list?.map((coin, index) => {            
            return (
              <PanelCardRow key={index}>
                <TokenInfo
                  icon={img}
                  name={coin?.title ? coin?.title : '-'}
                  label={coin?.current_price && `${coin?.current_price} $`}
                />
                <PanelCardValue value={coin?.status} />
              </PanelCardRow>
            );
          })}
        </PanelCard>
        <PanelCard className={style.panels__item} red title='Popular Coins'>
          {blocks?.popularCoins?.list?.map((coin, index) => {
            return (
              <PanelCardRow key={index}>
                <TokenInfo
                  icon={img}
                  name={coin?.title ? coin?.title : '-'}
                  label={coin?.transactions}
                />
                <PanelCardValue value={coin?.current_price} />
              </PanelCardRow>
            );
          })}
        </PanelCard>
        <PanelCard className={style.panels__item} grey title='Top Memes'>
          {blocks?.topMemes?.list?.map((coin, index) => {
            return (
              <PanelCardRow key={index}>
                <TokenInfo
                  icon={img}
                  name={coin?.title ? coin?.title : '-'}
                  label={coin?.transactions}
                />
                <PanelCardValue value={coin?.current_price} />
              </PanelCardRow>
            );
          })}
        </PanelCard>
        <PanelCard className={style.panels__item} green title='Airdrops'>
          {blocks?.airdrops?.list?.map((coin, index) => {
            return (
              <PanelCardRow key={index}>
                <TokenInfo
                  icon={img}
                  name={coin?.title ? coin?.title : '-'}
                  label={coin?.current_price && `${coin?.current_price} $`}
                />
                <PanelCardValue timestamp={coin?.created_at} />
              </PanelCardRow>
            );
          })}
        </PanelCard>
        <PanelCard className={style.panels__item} pink title='Crypto News'>
          {blocks?.cryptoNews?.list?.map((coin, index) => {
            return (
              <PanelCardNews
                img={img2}
                name={coin?.title ? coin?.title : '-'}
                text={coin?.description}
                key={index}
              />
            );
          })}
        </PanelCard>
        <PanelCard className={style.panels__item} blue2 title='Crypto Guides'>
          <PanelCardTags
            tags={blocks?.categories?.list?.map((item) => item?.title) || []}
          />
        </PanelCard>
      </Panels>
    </div>
  );
};

export default PanelsSection;
