import { Controller } from 'react-hook-form';
import Select from '../../../../components/Select/Select';
import SubmitCoinCard from '../../../SubmitCoin/modules/Card/Card';
import style from './Season.module.scss';

const SeasonSection = ({ control }) => {
  return (
    <SubmitCoinCard className={style.season} title={'Select report reason'}>
      <Controller
        control={control}
        name='reason'
        render={({ field: { onChange, name, value } }) => {
          return (
            <Select
              border
              icon={
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g clip-path='url(#clip0_429_249)'>
                    <path
                      d='M5.33334 5.33337H5.34001M3.04184 1.95821L1.95818 3.04187C1.72758 3.27247 1.61228 3.38777 1.52982 3.52233C1.45672 3.64162 1.40285 3.77168 1.37018 3.90773C1.33334 4.06118 1.33334 4.22424 1.33334 4.55036L1.33334 6.44972C1.33334 6.77584 1.33334 6.9389 1.37018 7.09235C1.40285 7.2284 1.45672 7.35846 1.52982 7.47775C1.61228 7.61231 1.72758 7.72761 1.95818 7.95821L7.0706 13.0706C7.86263 13.8627 8.25865 14.2587 8.71531 14.4071C9.11699 14.5376 9.54969 14.5376 9.95137 14.4071C10.408 14.2587 10.804 13.8627 11.5961 13.0706L13.0706 11.5961C13.8626 10.8041 14.2586 10.4081 14.407 9.95141C14.5375 9.54972 14.5375 9.11702 14.407 8.71534C14.2586 8.25868 13.8626 7.86266 13.0706 7.07063L7.95818 1.95821C7.72758 1.72761 7.61228 1.61231 7.47772 1.52985C7.35843 1.45675 7.22837 1.40288 7.09232 1.37021C6.93887 1.33337 6.77581 1.33337 6.44969 1.33337L4.55033 1.33337C4.22421 1.33337 4.06115 1.33337 3.9077 1.37021C3.77165 1.40288 3.64159 1.45675 3.5223 1.52985C3.38774 1.61231 3.27244 1.72761 3.04184 1.95821ZM5.66668 5.33337C5.66668 5.51747 5.51744 5.66671 5.33334 5.66671C5.14925 5.66671 5.00001 5.51747 5.00001 5.33337C5.00001 5.14928 5.14925 5.00004 5.33334 5.00004C5.51744 5.00004 5.66668 5.14928 5.66668 5.33337Z'
                      stroke='white'
                      stroke-opacity='0.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_429_249'>
                      <rect width='16' height='16' fill='white' />
                    </clipPath>
                  </defs>
                </svg>
              }
              big
              options={[
                {
                  name: 'Select a reason',
                  value: '',
                },
                {
                  name: 'Select a reason 1',
                  value: '1',
                },
                {
                  name: 'Select a reason 2',
                  value: '2',
                },
              ]}
              onChange={onChange}
              value={value}
            />
          );
        }}
      />
    </SubmitCoinCard>
  );
};

export default SeasonSection;
