import Text from '../../../../components/Text/Text';
import Title from '../../../../components/Title/Title';
import style from './MarketDetails.module.scss';

const MarketDetails = ({ coin }) => {
  return (
    <div className={style.details}>
      <div className='__container'>
        <Title.H1 className={style.details__title}>Market Details</Title.H1>
        <div className={style.detailsBody}>
          <div
            className={`${style.detailsBody__col} ${style.detailsBody__graph}`}
          >
            <svg
              width='36'
              height='42'
              viewBox='0 0 36 42'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g clip-path='url(#clip0_1425_17225)'>
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M21.6884 14.9612C22.9994 14.3206 24.6675 13.3776 26.3377 12.0855C26.6898 12.8022 26.7284 13.4275 26.547 13.9325C26.4184 14.2884 26.1761 14.5979 25.8575 14.8442C25.5125 15.1103 25.0817 15.3041 24.6054 15.4088C23.7017 15.6083 22.6465 15.4918 21.6884 14.9612ZM21.9157 21.5253L23.6511 22.5077C20.1077 24.4539 19.1444 28.0679 18.0001 31.5879C16.856 28.0679 15.8925 24.4539 12.3492 22.5077L14.0847 21.5253C14.2526 21.4631 14.396 21.3504 14.4946 21.2035C14.5931 21.0565 14.6417 20.8827 14.6332 20.707C14.4742 17.4104 15.3825 15.9526 16.6077 15.0373C17.0471 14.7094 17.5268 14.5447 18.0001 14.5447C18.4734 14.5447 18.9531 14.7094 19.3927 15.0373C20.6178 15.9526 21.5261 17.4104 21.3671 20.707C21.3587 20.8827 21.4072 21.0565 21.5058 21.2035C21.6043 21.3504 21.7478 21.4631 21.9157 21.5253ZM18.0001 0C20.0061 0.05278 22.0171 0.43442 23.7624 1.17684C24.971 1.69162 26.0987 2.3716 27.1198 3.18472C27.581 3.5518 27.9607 3.90642 28.379 4.31466C29.5071 4.35288 31.1558 3.12466 31.9212 1.9754C30.604 6.2076 24.5928 11.2055 20.4312 13.1179C20.4295 13.1172 20.4284 13.1162 20.4271 13.1153C19.6802 12.5565 18.8402 12.277 18.0001 12.277C17.16 12.277 16.3201 12.5565 15.5732 13.1153C15.572 13.116 15.5708 13.1173 15.5691 13.1179C11.4074 11.2055 5.39639 6.2076 4.0791 1.9754C4.84439 3.12466 6.4931 4.35288 7.62124 4.31466C8.03967 3.90656 8.41939 3.5518 8.88039 3.18472C9.90153 2.3716 11.0292 1.69162 12.2378 1.17684C13.9832 0.43442 15.9942 0.05278 18.0001 0ZM14.3118 14.9612C13.001 14.3206 11.3327 13.3776 9.66267 12.0855C9.31053 12.8022 9.27196 13.4275 9.45324 13.9325C9.58196 14.2884 9.82424 14.5979 10.1427 14.8442C10.4878 15.1103 10.9187 15.3041 11.395 15.4088C12.2987 15.6083 13.3537 15.4918 14.3118 14.9612Z'
                  fill='white'
                />
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M28.1667 10.5014C29.0861 9.59206 29.8963 8.5856 30.5477 7.6875L30.8786 8.29776C31.9437 10.3861 32.4971 12.4661 32.4971 14.8069L32.4949 18.5214L32.5149 20.447C32.592 25.1742 33.6357 29.957 36 34.334L31.0533 30.4249L27.5531 35.9912L23.876 32.5988L18 41.9437L12.124 32.599L8.447 35.9913L4.94686 30.425L0 34.3341C2.36429 29.9572 3.408 25.1743 3.48529 20.4471L3.50529 18.5215L3.503 14.8071C3.503 12.4661 4.05629 10.3861 5.12171 8.2979L5.45243 7.68764C6.10386 8.58574 6.91386 9.59206 7.83343 10.5015L7.54629 11.086C6.98843 12.2213 6.80371 13.4906 7.23829 14.6979C7.51843 15.4754 8.02971 16.1422 8.68886 16.6513C9.32886 17.1456 10.0863 17.4788 10.8793 17.6538C11.3959 17.7678 11.9221 17.8147 12.445 17.7987C12.323 18.4765 12.2697 19.1794 12.2663 19.8956L7.6 22.5369L11.2009 24.5149C11.4887 24.6731 11.7626 24.8542 12.02 25.0566C14.9887 27.6687 16.7736 35.3963 18.0001 39.1708C19.2269 35.3963 21.0116 27.6687 23.9804 25.0566C24.2378 24.8542 24.5117 24.673 24.7996 24.5149L28.4004 22.5369L23.734 19.8956C23.7306 19.1794 23.6773 18.4765 23.5553 17.7987C24.0781 17.8147 24.6044 17.7678 25.121 17.6538C25.914 17.4788 26.6716 17.1456 27.3114 16.6513C27.9704 16.1422 28.4819 15.4754 28.7619 14.6979C29.1966 13.4906 29.0117 12.2214 28.454 11.086L28.1669 10.5015L28.1667 10.5014Z'
                  fill='white'
                />
              </g>
              <defs>
                <clipPath id='clip0_1425_17225'>
                  <rect width='36' height='42' fill='white' />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div
            className={`${style.detailsBody__col} ${style.detailsBody__data}`}
          >
            <div className={style.detailsBody__dataTop}>
              <Title.H3 fz16 className={style.detailsBody__dataTitle}>
                Market Data
              </Title.H3>
              <a href='' className={style.detailsBody__dataLink}>
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M14 6L14 2M14 2H10M14 2L8.66667 7.33333M6.66667 3.33333H5.2C4.0799 3.33333 3.51984 3.33333 3.09202 3.55132C2.71569 3.74307 2.40973 4.04903 2.21799 4.42535C2 4.85318 2 5.41323 2 6.53333V10.8C2 11.9201 2 12.4802 2.21799 12.908C2.40973 13.2843 2.71569 13.5903 3.09202 13.782C3.51984 14 4.0799 14 5.2 14H9.46667C10.5868 14 11.1468 14 11.5746 13.782C11.951 13.5903 12.2569 13.2843 12.4487 12.908C12.6667 12.4802 12.6667 11.9201 12.6667 10.8V9.33333'
                    stroke='#878789'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </a>
            </div>
            <div className={style.detailsBody__dataContent}>
              <DataRow
                icon={
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4 10.6654C4 12.1381 5.19391 13.332 6.66667 13.332H9.33333C10.8061 13.332 12 12.1381 12 10.6654C12 9.19261 10.8061 7.9987 9.33333 7.9987H6.66667C5.19391 7.9987 4 6.80479 4 5.33203C4 3.85927 5.19391 2.66536 6.66667 2.66536H9.33333C10.8061 2.66536 12 3.85927 12 5.33203M8 1.33203V14.6654'
                      stroke='white'
                      stroke-opacity='0.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                }
                title={'Price'}
                value={`$ ${coin?.current_price}`}
              />
              <DataRow
                icon={
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M15.1333 7.66667L13.8004 9L12.4666 7.66667M13.9634 8.66667C13.9876 8.44778 14 8.22534 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C9.88484 14 11.5667 13.1309 12.6667 11.7716M8 4.66667V8L10 9.33333'
                      stroke='white'
                      stroke-opacity='0.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                }
                title={'Price Change / 24h'}
                diff={{
                  value: `${coin?.price_change_percentage_24h} %`,
                  type: 'positive',
                }}
              />
              <DataRow
                icon={
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g clip-path='url(#clip0_1425_17243)'>
                      <path
                        d='M7.99991 13.6468C8.70759 14.2802 9.64211 14.6654 10.6666 14.6654C12.8757 14.6654 14.6666 12.8745 14.6666 10.6654C14.6666 8.82075 13.418 7.26776 11.7198 6.80548M4.28004 6.80547C2.58188 7.26775 1.33325 8.82074 1.33325 10.6654C1.33325 12.8745 3.12411 14.6654 5.33325 14.6654C7.54239 14.6654 9.33325 12.8745 9.33325 10.6654C9.33325 10.145 9.2339 9.64791 9.05313 9.19192M11.9999 5.33203C11.9999 7.54117 10.2091 9.33203 7.99992 9.33203C5.79078 9.33203 3.99992 7.54117 3.99992 5.33203C3.99992 3.12289 5.79078 1.33203 7.99992 1.33203C10.2091 1.33203 11.9999 3.12289 11.9999 5.33203Z'
                        stroke='white'
                        stroke-opacity='0.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_1425_17243'>
                        <rect width='16' height='16' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>
                }
                title={'Market Cap'}
                value={`$ ${coin?.market_cap}`}
              />
              <DataRow
                icon={
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M11.9187 13.3933C10.7797 14.2208 9.40797 14.6665 8.00011 14.6665C6.59224 14.6665 5.22052 14.2208 4.08154 13.3933M10.9226 2.00788C12.188 2.62504 13.2255 3.62696 13.8864 4.87003C14.5474 6.11309 14.7978 7.5335 14.6019 8.92766M1.39838 8.92759C1.20245 7.53343 1.4529 6.11303 2.11385 4.86996C2.7748 3.62689 3.81232 2.62498 5.0777 2.00781M11.6668 7.99984C11.6668 10.0249 10.0251 11.6665 8.00011 11.6665C5.97506 11.6665 4.33344 10.0249 4.33344 7.99984C4.33344 5.97479 5.97506 4.33317 8.00011 4.33317C10.0251 4.33317 11.6668 5.97479 11.6668 7.99984Z'
                      stroke='white'
                      stroke-opacity='0.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                }
                title={'Total Liquidity'}
                value={coin?.liquidity}
              />
              <DataRow
                icon={
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M10.6666 2.31184C11.6544 2.80274 12.3333 3.82209 12.3333 5C12.3333 6.17791 11.6544 7.19726 10.6666 7.68816M11.9999 11.1776C13.0076 11.6336 13.9149 12.3767 14.6666 13.3333M1.33325 13.3333C2.63091 11.6817 4.39271 10.6667 6.33325 10.6667C8.2738 10.6667 10.0356 11.6817 11.3333 13.3333M9.33325 5C9.33325 6.65685 7.99011 8 6.33325 8C4.6764 8 3.33325 6.65685 3.33325 5C3.33325 3.34315 4.6764 2 6.33325 2C7.99011 2 9.33325 3.34315 9.33325 5Z'
                      stroke='white'
                      stroke-opacity='0.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                }
                title={'Holders'}
                value={coin?.holders}
              />
              <DataRow
                icon={
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M8.66659 3.33333C8.66659 4.06971 7.02496 4.66667 4.99992 4.66667C2.97487 4.66667 1.33325 4.06971 1.33325 3.33333M8.66659 3.33333C8.66659 2.59695 7.02496 2 4.99992 2C2.97487 2 1.33325 2.59695 1.33325 3.33333M8.66659 3.33333V6.30477C7.85225 6.54932 7.33325 6.91929 7.33325 7.33333M1.33325 3.33333V11.3333C1.33325 12.0697 2.97487 12.6667 4.99992 12.6667C5.88634 12.6667 6.69929 12.5523 7.33325 12.3619V7.33333M1.33325 6C1.33325 6.73638 2.97487 7.33333 4.99992 7.33333C5.88634 7.33333 6.69929 7.21895 7.33325 7.02857M1.33325 8.66667C1.33325 9.40305 2.97487 10 4.99992 10C5.88634 10 6.69929 9.88562 7.33325 9.69523M14.6666 7.33333C14.6666 8.06971 13.025 8.66667 10.9999 8.66667C8.97487 8.66667 7.33325 8.06971 7.33325 7.33333M14.6666 7.33333C14.6666 6.59695 13.025 6 10.9999 6C8.97487 6 7.33325 6.59695 7.33325 7.33333M14.6666 7.33333V12.6667C14.6666 13.403 13.025 14 10.9999 14C8.97487 14 7.33325 13.403 7.33325 12.6667V7.33333M14.6666 10C14.6666 10.7364 13.025 11.3333 10.9999 11.3333C8.97487 11.3333 7.33325 10.7364 7.33325 10'
                      stroke='white'
                      stroke-opacity='0.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                }
                title={'Volume / 24h'}
                value={coin?.volume_24h}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DataRow = ({ icon, title, value, diff }) => {
  return (
    <div className={style.dataRow}>
      <div className={style.dataRow__icon}>{icon}</div>
      <Text className={style.dataRow__title}>{title}</Text>
      {value && (
        <Text grey className={style.dataRow__value}>
          {value}
        </Text>
      )}
      {diff && (
        <Text
          middle
          className={`${style.dataRow__diff} ${
            diff.type === 'positive' && style.green
          }`}
        >
          <svg
            width='6'
            height='5'
            viewBox='0 0 6 5'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M2.56699 0.75C2.75944 0.416667 3.24056 0.416667 3.43301 0.75L5.16506 3.75C5.35751 4.08333 5.11695 4.5 4.73205 4.5H1.26795C0.883049 4.5 0.642487 4.08333 0.834937 3.75L2.56699 0.75Z'
              fill='#78E053'
            />
          </svg>
          {diff.value}
        </Text>
      )}
    </div>
  );
};

export default MarketDetails;
