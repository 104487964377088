import UseBlogHooks from '../../hooks/useBlogHooks';
import BlogContent from './modules/Content/Content';
import PopularSection from './modules/Popular/Popular';
import BlogTop from './modules/Top/Top';
import style from './index.module.scss';
import axios from 'axios';

const BlogPage = () => {
  const { activeCategoryList, setPage, page } =
    UseBlogHooks();

  return (
    <div className={style.blog}>
      <div className='__container'>
        <BlogTop />
        <BlogContent
          activeCategoryList={activeCategoryList}
          setPage={setPage}
          page={page}
        />
        <PopularSection activeCategoryList={activeCategoryList} />
      </div>
    </div>
  );
};

export default BlogPage;
