import style from './Table.module.scss';

const Table = {
  Container: ({ children, side, className, scrolled }) => {
    return (
      <div
        className={`${style.tableWrapper} ${className} ${
          side && style.withSide
        }`}
      >
        {side && side}
        {side && (
          <div className={style.tableScroll}>
            <table className={style.table}>{children}</table>
          </div>
        )}
        {scrolled && (
          <div className={`${style.tableScroll} ${style.scrolled}`}>
            <table className={style.table}>{children}</table>
          </div>
        )}
        {!side && !scrolled && (
          <table className={style.table}>{children}</table>
        )}
      </div>
    );
  },
  Head: ({ children }) => {
    return <thead className={style.tableHead}>{children}</thead>;
  },
  Body: ({ children }) => {
    return <tbody className={style.tableBody}>{children}</tbody>;
  },
  // Side: ({ children }) => {
  //   return <div className={style.tableSide}>{children}</div>;
  // },
  Side: ({ children, className }) => {
    return (
      <div className={`${style.tableSide__wrapper} ${className}`}>
        <table className={style.tableSide}>{children}</table>
      </div>
    );
  },
  Col: ({ children, right, center, sort }) => {
    return (
      <td className={`${style.tableCol} `}>
        <div
          className={`${style.tableCol__div} ${center && style.center} ${
            right && style.right
          }`}
        >
          {center && (
            <div className={`${style.div} ${center && style.center} `}>
              {children}
            </div>
          )}
          {right && (
            <div className={`${style.div} ${right && style.right} `}>
              {children}
            </div>
          )}
          {!right && !center && children}
          {sort && (
            <button className={style.tableCol__sort}>
              <svg
                width='12'
                height='12'
                viewBox='0 0 12 12'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M3.5 7.5L6 10L8.5 7.5M3.5 4.5L6 2L8.5 4.5'
                  stroke='white'
                  stroke-opacity='0.5'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </button>
          )}
        </div>
      </td>
    );
  },
  Row: ({ children, onClick }) => {
    return <tr className={style.tableRow} onClick={onClick}>{children}</tr>;
  },
};

export default Table;
