import { useContext, useState } from 'react';
import style from './New.module.scss';
import Purchase from '../../modules/Purchase/Purchase';
import NewCard from '../../components/NewCard/NewCard';
import iconCrown from '../../../../assets/btn_icons/crown.svg';
import { useGetPromotionsQuery } from '../../../../store/promotionsApi';
import { MyDataContext } from '../..';

const NewSection = () => {
  const [selectedCards, setSelectedCards] = useState([]);

  const chooseCard = (item, type) => {
    if (type === 'add') {
      if (selectedCards.filter((card) => card.id === item.id).length < 1) {
        setSelectedCards([...selectedCards, item]);
      }
    } else {
      setSelectedCards([
        ...selectedCards.filter((card) => card.id !== item.id),
      ]);
    }
  };

  const category = useContext(MyDataContext);

  const { data: promotionsList } = useGetPromotionsQuery(category);

  return (
    <div className={style.new}>
      <div className={style.newCards}>
        {promotionsList?.data?.map((item) => (
          <NewCard
            key={item.id}
            id={item.id}
            icons={['link', 'copy']}
            icon={iconCrown}
            name={item.template}
            text={item.title}
            price={`$${item?.price}`}
            label={'day'}
            showPos={true}
            className={style.newCards__item}
            title={item.title}
            dates={`From ${item.days_from} - to ${item?.days_to}`}
            choose={chooseCard}
            selectedCards={selectedCards}
          />
        ))}
      </div>
      <Purchase
        choose={chooseCard}
        className={style.newPurchase}
        cardList={selectedCards}
        setSelectedCards={setSelectedCards}
      />
    </div>
  );
};

export default NewSection;
