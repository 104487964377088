import { useNavigate } from 'react-router-dom';
import BlogCard from '../../../../components/BlogCard/BlogCard';
import Pagination from '../../../../components/Pagination/Pagination';
import img from './assets/img.jpg';
import style from './Content.module.scss';
import UseGeneralHooks from '../../../../hooks/useGeneralHooks';

const BlogContent = ({ activeCategoryList, setPage, page }) => {
  const {navigate} = UseGeneralHooks();

  return (
    <div className={style.container}>
      <div className={style.content}>
        {activeCategoryList?.posts?.articles?.map((article, index) => {
          return (
            <BlogCard
              key={index}
              className={style.content__card}
              img={article?.image ?? img}
              tag={article?.categories[0]?.title}
              title={article?.title}
              text={article?.announce}
              info={{
                date: article?.published_at,
                time: article.time_to_read,
              }}
              tagColor={article?.categories[0]?.color}
              onClick={() => {
                localStorage.setItem('articleSlug', article?.url);
                navigate(article?.url);
              }}
            />
          );
        })}
      </div>
      <Pagination
        className={style.content__pagination}
        setPage={setPage}
        page={page}
        total={activeCategoryList?.posts?.meta?.totalagesArr}
      />
    </div>
  );
};

export default BlogContent;
