import { useCallback, useEffect, useState } from 'react';
import { useGetBlogsByCategoryQuery, useGetBlogsQuery } from '../store/blogApi';

const UseBlogHooks = () => {
  const [activeCategory, setActiveCategory] = useState('all');
  const [page, setPage] = useState(1);
  const [pagesArr, setPagesArr] = useState();
  const { data: allBlogs } = useGetBlogsQuery();
  const { data: activeCategoryList, refetch } = useGetBlogsByCategoryQuery({
    category: activeCategory,
    page,
  });

  useEffect(() => {
    refetch();
  }, [page, activeCategory]);

  return {
    page,
    allBlogs,
    activeCategory,
    activeCategoryList,
    setActiveCategory,
    setPage,
  };
};

export default UseBlogHooks;
