import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './API';

export const watchlistApi = createApi({
  reducerPath: 'watchlistApi',
  tagTypes: ['Watchlist'],
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    getWatchlist: builder.query({
      query: ({ category, id }) => ({
        url: `/watchlist/${id}?category=${category}`,
        method: 'GET',
      }),
      invalidatesTags: ['Watchlist'],
    }),
    attachWatchlist: builder.mutation({
      query: (coin_id) => ({
        url: `/attach-to-watchlist?coin_id=${coin_id}`,
        body: { coin_id: coin_id },
        method: 'POST',
      }),
      invalidatesTags: ['Watchlist'],
    }),
    deleteWatchlist: builder.mutation({
      query: (coin_id) => ({
        url: `/detach-to-watchlist?coin_id=${coin_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Watchlist'],
    }),
    getWatchlistCoinTable: builder.query({
      query: ({ category, id }) => ({
        url: `/coins-table/watchlist?id=${id}&category=${category}`,
        method: 'GET',
      }),
      invalidatesTags: ['Watchlist'],
    }),
  }),
});

export const {
  useGetWatchlistQuery,
  useAttachWatchlistMutation,
  useDeleteWatchlistMutation,
  useGetWatchlistCoinTableQuery,
} = watchlistApi;
