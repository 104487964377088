import { useState } from 'react';
import Button from '../../Button/Button';
import Input from '../../Input/Input';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import Select from '../../Select/Select';
import Text from '../../Text/Text';
import Textarea from '../../Textarea/Textarea';
import Title from '../../Title/Title';
import UploadFile from '../../UploadFile/UploadFile';
import style from './Recovery.module.scss';
import icon from './assets/icon.svg';
import ForgetPassword from '../../Forms/forgetPasswordForm';
import ResetPasswordForm from '../../Forms/resetPasswordForm';

const RecoveryModal = ({ close }) => {
  const [isSwitched, setIsSwitched] = useState(false);
  const [email, setEmail] = useState("")  

  return (
    <ModalWrapper closeModal={close} withBack={!isSwitched}>
      <div className={style.modal}>
        {isSwitched ? (
          <>
            <Title.H1 center className={style.modal__title}>
              Create new password
            </Title.H1>
            <Text grey center>
              Use this form below to reset <br /> your password
            </Text>
            <ResetPasswordForm email={email}/>
          </>
        ) : (
          <>
            <div className={style.modal__icon}>
              <img src={icon} alt='' />
            </div>
            <Title.H1 center className={style.modal__title}>
              Password recovery
            </Title.H1>
            <Text grey center>
              Enter your email address below <br /> to reset your password
            </Text>
           <ForgetPassword setIsSwitched={setIsSwitched} setEmail={setEmail} />
          </>
        )}
      </div>
    </ModalWrapper>
  );
};

export default RecoveryModal;
